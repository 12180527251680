<template>
  <div class="event-activity-editor">
    <div class="tabs is-small">
      <ul>
        <li class="is-active"><a>Activity</a></li>
      </ul>
    </div>

    <div v-if="needsActivity" class="mb-4">
      <div class="create-activity field has-addons">
        <div class="control">
          <a class="button is-link is-small" @click="createActivity"> Create activity </a>
        </div>
        <div class="control">
          <input
            v-model="activityTitle"
            class="input portfolio-title is-small"
            type="text"
            placeholder="Meeting title"
          />
        </div>
        <div v-show="showActivityTypeSelect" class="control">
          <div class="select is-small">
            <select v-model="selectedActivityType" @change="checkActivityTitle">
              <option
                v-for="activityType in activityTypes[eventCategory]"
                :key="activityType"
                :value="activityType"
              >
                {{ activityTypeName(activityType) }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="matchesExist" class="link-activity field has-addons">
        <div class="control">
          <a class="button is-link is-small" @click="linkActivity"> Link existing </a>
        </div>
        <div class="control">
          <div class="select is-small">
            <select v-model="matchingEventId">
              <option v-for="match in possibleMatches" :key="match.id" :value="match.id">
                {{ activityDescription(match) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasActivity" class="note-editor">
      <NoteEditor v-model="noteContent" :save-interrupted="noteSaveKey" />
    </div>

    <div v-if="hasActivity" class="note-writing">
      <button v-if="!noteExists" class="button" :disabled="disableSubmit" @click="updateNote">
        Add note
      </button>
      <button v-else class="button" @click="updateNote">Update note</button>
    </div>
  </div>
</template>

<script>
import NoteEditor from "@/components/NoteEditor.vue";
import { clearNoteCache, retrieveNoteCache } from "@/common/notes";
import { activities } from "@/common/pipedrive/resources";
import { buildParams } from "@/common/pipedrive/activities";
import { useCalendarStore } from "@/stores/calendar";
import { toDate, toUTCDateString } from "@/utils/date";
import { format as dateFormat } from "@/utils/dateFormatter";
import { stripTags } from "@/utils/html";
import { capitalize } from "@/utils/stringFormatter";

const ACTIVITY_TYPES = {
  pipeline: ["pipeline_meeting"],
  portfolio: ["partner_meeting", "board_meeting"],
  networking: ["networking_meeting"],
};

export default {
  components: {
    NoteEditor,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
      default: null,
    },
    noteWritten: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["set-activity"],
  setup() {
    const calendarStore = useCalendarStore();
    return { calendarStore };
  },
  data() {
    return {
      activityTitle: "Meeting",
      activityTypes: ACTIVITY_TYPES,
      matchingEventId: null,
      noteContent: "",
      persistedNote: false,
      possibleMatches: [],
      selectedActivityType: null,
      categoryResourceTypes: {
        pipeline: "deal",
        portfolio: "organization",
        networking: "person",
      },
    };
  },
  computed: {
    eventActivityId() {
      return this.event?.extendedProperties?.private?.activityId;
    },
    eventCategory() {
      return this.event?.extendedProperties?.private?.eventCategory;
    },
    eventResource() {
      // If the event's properties reference a resource, set the local linked resource to that
      const resourceType = this.categoryResourceTypes[this.eventCategory];
      if (!resourceType) return {};

      let idKey = `${resourceType}Id`;
      let nameKey = resourceType == "deal" ? "dealTitle" : `${resourceType}Name`; // dealTitle is used instead of dealName
      const eventProperties = this.event?.extendedProperties?.private || {};

      return {
        type: resourceType,
        id: eventProperties[idKey],
        name: eventProperties[nameKey],
      };
    },
    disableSubmit() {
      return !this.noteContent.length;
    },
    hasActivity() {
      return !!this.eventActivityId;
    },
    needsActivity() {
      let properties = this.event?.extendedProperties?.private || {};
      let hasCategory = ["portfolio", "pipeline", "networking"].includes(properties.eventCategory);
      return hasCategory && this.eventResource?.id != null && !this.hasActivity;
    },
    matchesExist() {
      return this.possibleMatches.length;
    },
    noteExists() {
      if (!this.activity?.note?.length) return false;
      // strip out HTML tags since note can appear empty but have html
      const stripped = stripTags(this.activity.note);
      return stripped.length > 0;
    },
    noteCacheKey() {
      if (!this.hasActivity) return null;
      return "activity." + this.eventActivityId;
    },
    noteSaveKey() {
      if (this.persistedNote) return null;
      return this.noteCacheKey;
    },
    showActivityTypeSelect() {
      if (!this.eventCategory) return false;
      return this.activityTypes[this.eventCategory].length > 1;
    },
  },
  watch: {
    event: function (newVal, oldVal) {
      if (newVal && newVal != oldVal) this._checkActivityContext();
    },
    activity(newVal) {
      this._setActivityNote(newVal);
    },
  },
  mounted() {
    this._checkActivityContext();
  },
  methods: {
    activityDescription(activity) {
      let time = "All Day";
      if (activity.due_time) {
        const pd_time = activity.due_date + " " + activity.due_time + ":00";
        time = dateFormat(pd_time, "HH:mm"); // adjust from UTC
      }
      const participantCount = activity.participants?.length || 0;
      return `${time} - ${activity.subject} - ${participantCount} ppl`;
    },
    activityTypeName(activityType) {
      const words = activityType.split("_");
      return words.map((word) => capitalize(word)).join(" ");
    },
    checkActivityTitle() {
      this._updateActivityTitle();
    },
    createActivity() {
      // Create a payload for the activity endpoint
      let resourceId = Number(this.eventResource.id);
      const opts = {
        deal_id: this.eventResource.type === "deal" ? resourceId : null,
        org_id: this.eventResource.type === "organization" ? resourceId : null,
        person_id: this.eventResource.type === "person" ? resourceId : null,
      };
      const params = buildParams(this.activityTitle, this.selectedActivityType, this.event, opts);

      // Create the activity
      activities.create(params).then(
        (response) => {
          this.calendarStore.addActivity(response);
          this.$emit("set-activity", { activityId: response.id });
        },
        (error) => {
          console.error("request failed: ", error.response);
          this.$toasted.error(`Error creating ${this.eventCategory} activity`);
        }
      );
    },
    linkActivity() {
      const activity = this.possibleMatches.find((m) => m.id == this.matchingEventId);
      this.calendarStore.addActivity(activity);
      this.$emit("set-activity", { activityId: activity.id });
      this.$toasted.info("Linked to existing activity.");
    },
    toggleNoteStatus() {
      this.$emit("set-activity", { note: !this.noteWritten });
    },
    updateNote() {
      this._commitActivityNote();
    },
    _activityFilter(activity) {
      const start = toDate(this.event.start.dateTime);
      const [date] = start.toISOString().split("T");
      if (activity.due_date != date) return false;
      // maybe match participants too?
      return true;
    },
    _buildActivityParams(opts = {}) {
      return buildParams(this.activityTitle, this.selectedActivityType, this.event, opts);
    },
    _checkActivityContext() {
      if (this.activity) {
        this._setActivityNote();
      } else if (!this.hasActivity) {
        this._fetchPossibleMatches();
        this._setSelectedActivityType();
        this._updateActivityTitle();
      }
    },
    _commitActivityNote() {
      const params = { note: this.noteContent };
      const isEmpty = stripTags(this.noteContent).length < 1;
      activities.update(this.activity.id, params).then(
        (response) => {
          this.calendarStore.updateActivity(response);
          // if note content is present mark note as written
          // if it is empty, mark note as needed again
          this.$emit("set-activity", { note: !isEmpty });
          if (!this.persistedNote) {
            // this is the first time a note has been saved to this activity,
            // update state / cache appropriately.
            this.persistedNote = true;
            clearNoteCache(this.noteCacheKey);
          }
          this.$toasted.success("Note saved.");
        },
        (error) => {
          console.error("request failed: ", error.response);
          this.$toasted.error("Error saving note to activity");
        }
      );
    },
    _fetchPossibleMatches() {
      if (!["pipeline", "portfolio", "networking"].includes(this.eventCategory)) return;
      const startTime = this.event.start.dateTime;
      let nextDay = new Date(startTime);
      nextDay.setDate(nextDay.getDate() + 1);
      const params = {
        start_date: toUTCDateString(startTime),
        end_date: toUTCDateString(nextDay),
      };
      activities.filtered(params).then(
        (response) => {
          if (!response || !response.length) {
            this._resetMatches();
          } else {
            this.possibleMatches = response.filter(this._activityFilter);
            // set selected to first match
            if (this.possibleMatches.length) this.matchingEventId = this.possibleMatches[0].id;
          }
        },
        (error) => {
          console.error("error", error);
          this.$toasted.error("Check for existing activity failed.");
        }
      );
    },
    _resetMatches() {
      this.possibleMatches = [];
      this.matchingEventId = null;
    },
    _setActivityNote(activity = this.activity) {
      if (!activity?.note?.length) {
        this.noteContent = retrieveNoteCache(this.noteCacheKey) || "";
        this.persistedNote = false;
      } else {
        this.noteContent = activity.note;
        this.persistedNote = true;
      }
    },
    _setSelectedActivityType() {
      if (this.eventCategory && this.activityTypes[this.eventCategory]) {
        this.selectedActivityType = this.activityTypes[this.eventCategory][0];
      }
    },
    _updateActivityTitle() {
      if (this.eventCategory === "pipeline") {
        let meetingTitle = "Pipeline Meeting";
        let resourceTitle = this.eventResource.name ? ` re: ${this.eventResource.name}` : "";
        this.activityTitle = meetingTitle + resourceTitle;
        return;
      }

      if (this.eventCategory === "portfolio") {
        let type = this.selectedActivityType;
        let meetingTitle = type === "board_meeting" ? "Board Meeting: " : "Partner Office Hours: ";
        this.activityTitle = meetingTitle + this.eventResource.name;
        return;
      }

      if (this.eventCategory === "networking") {
        let meetingTitle = "Networking meeting";
        let resourceTitle = this.eventResource.name ? ` w/ ${this.eventResource.name}` : "";
        this.activityTitle = meetingTitle + resourceTitle;
        return;
      }

      this.activityTitle = "Other meeting";
    },
  },
};
</script>

<style lang="sass" scoped>
.create-activity
  .button
    width: 6rem
  input
    width: 19rem
.link-activity
  display: flex
  align-items: baseline
  margin-bottom: 4px
  select
    min-width: 19rem
  .button
    width: 6rem
.note-writing
  display: flex
  align-items: baseline
  margin: 4px 0 8px 0
  a.button
    margin-right: 8px
.event-activity-editor
  .tabs.is-small
    margin-bottom: 0.5rem
    li a
      padding: 0.1rem 0.4rem 0.1rem 0.4rem
    + div
      min-height: 10rem
</style>
