<template>
  <section class="team-members section">
    <!-- Team members title bar-->
    <div class="level">
      <div class="level-left">
        <h2 class="is-size-3">
          Team <small class="has-text-grey-light">({{ filteredPeople.length }})</small>
        </h2>
      </div>

      <div class="level-right">
        <div class="level-item">
          <div class="field">
            <label class="field-label is-small has-text-weight-semibold" for="showVirtual">
              Show Virtual Contacts:
            </label>
            <input id="showVirtual" v-model="showVirtualContacts" type="checkbox" />
          </div>
        </div>

        <div class="level-item">
          <div class="control has-icons-left">
            <input v-model="filterText" class="input is-small" placeholder="Filter team..." />
            <span class="icon is-small is-left">
              <font-awesome-icon icon="filter" />
            </span>
          </div>
        </div>

        <a class="button level-item is-small" :class="{ 'is-loading': loading }" @click="loadTeam">
          Refresh
        </a>

        <a class="button level-item is-small" target="_blank" :href="organizationUrl"> Manage </a>
      </div>
    </div>

    <!-- Team members listing -->
    <div class="team-container" @dragover.prevent="hoverPerson" @drop.prevent="dropPerson">
      <PersonCard
        v-for="person in filteredPeople"
        :key="person.id"
        :person="person"
        :highlight-text="filterText"
        @update-profile="updatePerson"
      />
    </div>

    <!-- Search toolbar -->
    <!-- <div class="team-search" v-if="showSearchBox"> -->
    <div class="search-bar level">
      <div class="level-item has-text-centered">
        <div class="field has-addons">
          <div class="control has-icons-left">
            <input
              v-model="searchText"
              class="input is-small"
              placeholder="Search by email"
              @keyup.enter="searchPeople"
            />
            <span class="icon is-left">
              <font-awesome-icon icon="search" />
            </span>
          </div>

          <div class="control">
            <a
              class="button is-info is-small"
              :class="{ 'is-loading': searching }"
              @click="searchPeople"
              >Search</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Search results -->
    <div class="team-container">
      <PersonCard
        v-for="person in searchResults"
        :key="person.id"
        :person="person"
        :highlight-text="searchText"
        :load-all-data="searchResults.length < 30"
        @more-data="updateSearchResult"
        @update-profile="updatePerson"
      />
    </div>
    <!-- </div> -->
  </section>
</template>

<script>
import PersonCard from "@/components/PersonCard.vue";
import { people } from "@/common/pipedrive/resources";
import pipedrive from "@/common/pipedrive";

export default {
  components: {
    PersonCard,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      showVirtualContacts: false,
      loading: true,
      searching: false,
      people: [],
      filterText: "",
      searchText: "",
      searchResults: [],
    };
  },
  computed: {
    organizationUrl() {
      return pipedrive.web.organizationUrl(this.id);
    },
    filteredPeople() {
      var people = this.people.slice(); // Slice to create copy & avoid sorting in place
      if (this.filterText.length > 2) {
        const filterText = this.filterText.toLowerCase();
        const nameFilter = (p) => {
          const fields = ["name", "job_title"];
          return fields.find((key) => (p[key] ? p[key].toLowerCase().includes(filterText) : false));
        };

        people = people.filter(nameFilter).sort(this.sortPeople);
      }

      const virtualTag = 177;
      const tagsKey = "0c74485a9aba936bac4606213d946ddd6cb64b71";
      const isVirtual = (p) => !(p[tagsKey] || "").includes(virtualTag);
      if (!this.showVirtualContacts) people = people.filter(isVirtual);

      return people.sort(this.sortPeople);
    },
    ids() {
      return this.people.map((p) => p.id);
    },
  },
  mounted() {
    this.loadTeam();
  },
  methods: {
    loadTeam() {
      // get the team members
      this.loading = true;
      pipedrive.api
        .get("organizations/" + this.id + "/persons")
        .then((response) => (this.people = response.data.data || []))
        .catch((err) => console.log(err))
        .finally(setTimeout(() => (this.loading = false), 1000));
    },
    sortPeople(p1, p2) {
      const founderTag = 173;
      const tagsKey = "0c74485a9aba936bac4606213d946ddd6cb64b71";
      const isFounder = (p) => (p[tagsKey] || "").includes(founderTag);
      if (isFounder(p1) && !isFounder(p2)) return -1;
      if (!isFounder(p1) && isFounder(p2)) return 1;
      if (p1.last_name < p2.last_name) return -1;
      if (p1.last_name > p2.last_name) return 1;
      return 0;
    },
    searchPeople() {
      if (this.searchText.length < 3) return (this.searchResults = []);
      people
        .byEmail(this.searchText, { include_fields: "person.picture" })
        .then((results) => {
          this.searchResults = results;
          if (!results.length) this.$toasted.info("No search results found");
        })
        .catch(() => this.$toasted.error(`Error searching for ${this.searchText}`));
    },
    addPerson(person) {
      if (this.ids.includes(person.id)) {
        return this.$toasted.info(`${person.name} is already a team member.`);
      }

      const data = { id: person.id, org_id: this.id };
      pipedrive.api
        .put("persons/" + person.id, data)
        .then((response) => {
          if (!response.data.success) {
            console.log(response);
            return this.$toasted.error(`Error saving '${person.name}'`);
          }

          person = response.data.data;
          this.people.push(person);
          this.searchResults = this.searchResults.filter((p) => p.id != person.id);
          this.$toasted.success(`Added '${person.name}'`);
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error(err.message);
        });
    },
    hoverPerson() {},
    dropPerson(event) {
      const format = "application/json";
      const data = event.dataTransfer.getData(format);
      const person = JSON.parse(data);
      this.addPerson(person);
    },
    updatePerson(id, details) {
      const index = this.people.findIndex((p) => p.id === id);
      if (index == -1) {
        this.$toasted.error("Could not find person to update");
        return;
      }
      const person = this.people[index];

      // apply updates locally
      Object.entries(details).forEach(([key, value]) => (person[key] = value));

      // persist updates
      people
        .update(id, details)
        .then((response) => {
          // update with merged entry
          this.people[index] = response;
          this.$toasted.success("Team member enriched and saved");
        })
        .catch(() => this.$toasted.error("Could not update profile details for person"));
    },
    updateSearchResult(id, details) {
      // child component has loaded more details for a search result person
      const index = this.searchResults.findIndex((p) => p.id === id);
      if (index == -1) {
        this.$toasted.error("Could not find person to set details on");
        return;
      }
      this.searchResults[index] = details;
    },
  },
};
</script>

<style lang="sass" scoped>
.team-container
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  align-content: flex-start
  min-height: 20rem
  margin-top: 1rem
  overflow: scroll
  .box
    margin: 0.5rem
.team-members .level .level-right
  opacity: 0.2
.team-members:hover .level .level-right
  opacity: 1
  transition: 0.1s linear
.search-bar
  border-bottom: 1px solid #dbdbdb
  padding-top: 0.5rem
  margin-bottom: 1.5rem
  div
    top:0.9rem
.search-bar:focus-within
  border-bottom-color: #209cee77 !important
.search-bar + .team-container
  max-height: 22rem
=mobile
.team-members .level .level-right
  opacity: 1
  .control.has-icons-left
    width: 100%
</style>
