import { watch } from "vue";
import axios from "axios";
import { useUserStore } from "@/stores/user";

const BASE_URL = "https://api.pipedrive.com/v1/";

const defaultParams = function () {
  const userStore = useUserStore();
  return {
    api_token: userStore.pipedriveToken,
    start: 0, // default pagination to beginning
  };
};

// resolves when pipedrive credentials are fully loaded and we are ready for requests
const ensureReady = function () {
  return new Promise((resolve) => {
    const userStore = useUserStore();
    if (userStore.pipedriveReady) return resolve();
    // resolve when the store tells us we are ready
    watch(
      () => userStore.pipedriveReady,
      (newVal) => {
        if (newVal) resolve();
      }
    );
  });
};

export default {
  // methods
  get: async function (path, queryParams = {}) {
    await ensureReady();
    const url = this.urlFor(path);
    const params = this.params(queryParams);
    return axios.get(url, { params }); // returns promise
  },
  post: async function (path, data = {}, queryParams = {}) {
    await ensureReady();
    const url = this.urlFor(path);
    const params = this.params(queryParams);
    return axios.post(url, data, { params }); // returns promise
  },
  put: async function (path, data = {}, queryParams = {}) {
    await ensureReady();
    const url = this.urlFor(path);
    const params = this.params(queryParams);
    return axios.put(url, data, { params }); // returns promise
  },
  delete: async function (path, queryParams = {}) {
    await ensureReady();
    const url = this.urlFor(path);
    const params = this.params(queryParams);
    return axios.delete(url, { params }); // returns promise
  },
  params(opts = {}) {
    return Object.assign(defaultParams(), opts);
  },
  urlFor(resource) {
    return `${BASE_URL}${resource}`;
  },
};
