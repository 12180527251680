import { lastMetricValue, lastValues } from "@/utils/measurements";

// defined ranges depending on the percentage
export const percentageThreshold = (value) => {
  if (value > 5.0) {
    return "improving";
  } else if (value > 0.0) {
    return "stable";
  } else {
    return "declining";
  }
};

export const lastARR = (measurements) => {
  const lastMRR = lastMetricValue(measurements, "MRR");
  return lastMRR ? lastMRR * 12 : null;
};

export const currentRunway = (measurements) => {
  const lastCash = lastMetricValue(measurements, "cash");
  const lastBurn = lastMetricValue(measurements, "burn");
  if (lastCash && lastBurn && lastBurn > 0) {
    return lastCash / lastBurn;
  } else {
    return null;
  }
};

export const t4Growth = (measurements, metric) => {
  const latestMeasurements = lastValues(measurements, metric, 5);
  if (latestMeasurements.length < 5) return null;
  return trailingAverageGrowth(latestMeasurements.map((m) => m.value));
};

export const trailingAverageGrowth = (measurements) => {
  if (!measurements || measurements.length < 2) return null;
  let growth = [];
  for (var index = 0; index < measurements.length - 1; index++) {
    growth.push((measurements[index + 1] - measurements[index]) / measurements[index]);
  }
  const average = growth.reduce((first, second) => first + second, 0) / growth.length;
  return parseFloat(average * 100.0);
};

export default {
  percentageThreshold,
  lastARR,
  currentRunway,
  trailingAverageGrowth,
};
