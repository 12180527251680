<template>
  <div class="note media">
    <figure class="media-left">
      <img
        :src="userIconUrl || '/img/avatar_120x120.png'"
        class="avatar small"
        :alt="userName"
        :title="userName"
      />
    </figure>
    <div class="media-content">
      <div class="note-subject">
        <div>
          <div class="subject">{{ subject }}</div>
          <div class="occurred is-size-7">{{ occurred }}</div>
        </div>
        <div class="organization">
          <a :href="orgUrl" target="_blank">{{ orgName }}</a>
        </div>
      </div>
      <template v-if="activityType !== 'introduction'">
        <div v-if="hasContent" class="note-content" v-html="content"></div>
        <div v-else class="no-content">No note found</div>
      </template>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/common/filters";
import { organizationUrl } from "@/common/pipedrive/web";
import { sanitizeHTML } from "@/common/pipedrive/util";
import { stripTags } from "@/utils/html";

export default {
  props: {
    note: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    activityType() {
      return this.note?.type || "";
    },
    content() {
      if (!this.note?.content) return "";
      return sanitizeHTML(this.note.content, true, 40);
    },
    hasContent() {
      return stripTags(this.note?.content).length > 0;
    },
    occurred() {
      if (!this.note?.start) return "";
      return dateFormat(this.note.start, "h:mmaaa LLL d y");
    },
    orgName() {
      return this.note?.org_name || "";
    },
    orgUrl() {
      if (this.note?.org_id == null) return "";
      return organizationUrl(this.note.org_id);
    },
    subject() {
      return this.note?.subject || "";
    },
    userIconUrl() {
      return this.note?.user?.icon_url || undefined;
    },
    userName() {
      return this.note?.user?.name || "";
    },
  },
};
</script>

<style lang="sass" scoped>
.note-subject
  display: flex
  justify-content: space-between
  font-weight: bold
  font-size: 0.8rem
  margin-bottom: 1rem
  .occurred
    font-weight: normal
  .organization
    margin-right: 6px
    font-weight: normal
.note-content
  font-weight: 400
  font-size: 0.8rem
  color: #262626
  margin-bottom: 1.5rem
  margin-right: 1.5rem
  // format injected note content
  :deep(p),
  :deep(ol),
  :deep(ul)
    margin-top: 0.5rem
    margin-bottom: 0.5rem
  :deep(ol),
  :deep(ul)
    margin-left: 1.3rem
  :deep(ul)
    font-weight: 400
    list-style: disc outside
    ul, ol
      margin-top: 0.25rem
      margin-left: 0.75rem
    ul
      list-style: circle outside
  :deep(li)
    font-weight: 400
  :deep(li > p)
    margin: 0
  :deep(b),
  :deep(strong)
    font-weight: 600
.no-content
  font-weight: 400
  font-size: 0.8rem
  font-style: italic
  color: #bbbbbb
  background: #fbfbfb
  margin-right: 1.5rem
  padding: 0.5rem
  text-align: center
</style>
