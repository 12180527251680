<template>
  <div class="pipedrive-editor" :class="editorClass" @dragstart.stop.prevent>
    <div v-if="editor" class="editor-controls">
      <button
        class="button is-small is-white"
        :class="{ 'is-active': editor.isActive('bold') }"
        @click="format('bold')"
      >
        <span class="icon is-small"><font-awesome-icon icon="bold" /></span>
      </button>
      <button
        class="button is-small is-white"
        :class="{ 'is-active': editor.isActive('italic') }"
        @click="format('italic')"
      >
        <span class="icon is-small"><font-awesome-icon icon="italic" /></span>
      </button>
      <button
        class="button is-small is-white"
        :class="{ 'is-active': editor.isActive('strikethrough') }"
        @click="format('strikethrough')"
      >
        <span class="icon is-small"><font-awesome-icon icon="strikethrough" /></span>
      </button>

      <span class="divider" />

      <button
        class="button is-small is-white"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        @click="toList('bullet')"
      >
        <span class="icon is-small"><font-awesome-icon icon="list-ul" /></span>
      </button>
      <button
        class="button is-small is-white"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        @click="toList('ordered')"
      >
        <span class="icon is-small"><font-awesome-icon icon="list-ol" /></span>
      </button>
    </div>
    <EditorContent :editor="editor" />

    <!-- Raw HTML source code -->
    <!-- <textarea class="textarea is-small is-size-7" v-model="content"></textarea> -->
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

import { clearNoteCache, setNoteCache } from "@/common/notes";
import pipedrive from "@/common/pipedrive";
import { stripTags } from "@/utils/html";

export default {
  components: {
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    // should the editor cache and recover contents when not explicitly cleared?
    // enable this with a unique key when writing a new note to ensure the note
    // isn't lost when interrupted and not saved (window closed, etc.)
    saveInterrupted: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      editor: null,
      isEditing: true,
      editedContent: null,
    };
  },
  computed: {
    editorClass() {
      return {
        "is-editing": this.isEditing,
        // "is-empty": this.isEmpty,
        "is-small": this.small,
      };
    },
    // isEmpty() {
    //   let content = this.editedContent || this.content;
    //   return content == null || content.length == 0;
    // },
  },
  watch: {
    isEditing() {
      this.editor.setEditable(this.isEditing);
    },
    modelValue(newVal) {
      if (this._sanitizedContent() == newVal) return;
      this.editor.commands.setContent(this._sanitizeHTML(newVal), false);
      // if parent sets editor to empty, clear localstorage cache
      if (this.saveInterrupted && newVal == "") clearNoteCache(this.saveInterrupted);
    },
  },
  mounted() {
    this._createEditor();
  },
  beforeUnmount() {
    this.editor?.destroy();
  },
  methods: {
    // types: bold, italic, strikethrough
    format(type) {
      if (!this.editor) return;
      const editorChain = this.editor.chain().focus();
      switch (type) {
        case "bold":
          editorChain.toggleBold().run();
          break;
        case "italic":
          editorChain.toggleItalic().run();
          break;
        case "strikethrough":
          editorChain.toggleStrike().run();
          break;
        default:
          console.error(`Format requested with unknown type, ${type}.`);
      }
    },
    // types: bullet, ordered
    toList(type) {
      if (!this.editor) return;
      const editorChain = this.editor.chain().focus();
      switch (type) {
        case "bullet":
          editorChain.toggleBulletList().run();
          break;
        case "ordered":
          editorChain.toggleOrderedList().run();
          break;
        default:
          console.error(`Format requested with unknown type, ${type}.`);
      }
    },
    _createEditor() {
      this.editedContent = this._sanitizeHTML(this.modelValue || "");
      this.editor = new Editor({
        // autofocus: true,
        content: this.modelValue,
        editable: this.isEditing,
        extensions: [StarterKit, Link],
        injectCSS: false,
        onUpdate: this._onUpdate,
      });
    },
    _onUpdate() {
      const content = this._sanitizedContent();
      this.$emit("update:modelValue", this._sanitizedContent());
      this._saveToCache(content);
    },
    _sanitizedContent() {
      return this._sanitizeHTML(this.editor.getHTML());
    },
    _sanitizeHTML(html) {
      return pipedrive.util.sanitizeHTML(html, true, 12);
    },
    _saveToCache(content) {
      if (!this.saveInterrupted) return;
      // is there any real text? if so cache it. the editor maintains a
      // global localstorage cache of unfinished notes. the cache is
      // cleared when a user empties the text out of the editor or if a
      // parent sets the editor content to empty via the modelValue prop
      if (stripTags(content).trim().length) {
        setNoteCache(this.saveInterrupted, content);
      } else {
        clearNoteCache(this.saveInterrupted);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.editor-controls
  display: flex
  align-items: center
  background: white
  border: 1px solid #dbdbdb
  border-bottom: 0
  border-radius: 2px
  padding: 0 4px
  button
    .icon
      opacity: 0.7
  .divider
    border-left: 1px solid #ededed
    margin-left: 6px
    margin-right: 6px
    height: 18px

.pipedrive-editor :deep(.ProseMirror)
  // basic editor styles
  box-sizing: border-box
  font-family: inherit
  overflow: auto
  // font-weight: 600
  font-size: 0.9rem
  white-space: pre-wrap
  // changes when editable (not read-only)
  &[contenteditable=true]
    border: 1px solid #dbdbdb
    border-bottom-left-radius: 2px
    border-bottom-right-radius: 2px
    resize: vertical
    min-height: 150px
    max-height: 30vh
    padding: 12px 12px

  // p:only-child
  //   font-weight: 400
  ol, ul
    margin-left: 1.6rem
    margin-top: 0.4rem
  ul
    font-weight: 400
    list-style: disc outside
    ul
      list-style: circle outside
  li > p
    margin: 0
  // > div:last-child
  //   font-weight: 600
  div > p,
  div > div
    margin-bottom: 0
  b, strong
    font-weight: 600

// disable browser focus outline when editing
.pipedrive-editor :deep(.ProseMirror-focused)
  outline-style: none
</style>
