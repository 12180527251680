<template>
  <tr :class="memberStageClass">
    <td class="text-left">
      <router-link :to="{ name: 'member-show', params: { id: memberId } }" class="has-text-primary">
        {{ name }}
      </router-link>
    </td>
    <td>
      <span class="arr">{{ arr }}</span>
      <span v-if="t4RevenueGrowth" class="t4growth" :class="trailingColor">
        <font-awesome-icon v-if="trailingPositive" icon="caret-up" />
        <font-awesome-icon v-if="trailingNegative" icon="caret-down" />
        {{ t4RevenueGrowth.toFixed(1) }}%
      </span>
    </td>
    <td>{{ runway }}</td>
    <td>{{ member.customers }}</td>
    <td>
      <span :title="toMDY(enteredProgram)">{{ member.programMonth }}</span>
    </td>
    <td>{{ lastUpdate }}</td>
  </tr>
</template>

<script>
import { t4Growth } from "@/utils/analysis";
import { currencyShort } from "@/utils/numberFormatter";
import { percentageThreshold } from "@/utils/analysis";
import { dateStringToMDY } from "@/utils/dateFormatter";
import { isPositive, isNegative } from "@/utils/number";

export default {
  props: {
    member: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    arr() {
      const arr = this.member.arr;
      return arr ? currencyShort(arr) : "-";
    },
    enteredProgram() {
      return this.member && this.member.entered_heavybit_on;
    },
    lastUpdate() {
      if (!this.member.updated) return "-";
      // eslint-disable-next-line no-unused-vars
      const [year, month, _day] = this.member.updated.split("-");
      return `${month}/${year.substring(2)}`;
    },
    memberId() {
      return this.member && this.member.id;
    },
    memberStage() {
      return this.member && this.member.member_stage && this.member.member_stage.name;
    },
    memberStageClass() {
      return {
        "stage-active": this.memberStage === "active",
        "stage-graduate": this.memberStage === "graduate",
      };
    },
    name() {
      return this.member && this.member.name;
    },
    runway() {
      const run = this.member.runway;
      return run ? run.toFixed(1) : "-";
    },
    t4RevenueGrowth() {
      const measurements = this.member ? this.member.measurements : [];
      return t4Growth(measurements, "MRR");
    },
    trailingColor() {
      return "is-" + percentageThreshold(this.t4RevenueGrowth);
    },
    trailingPositive() {
      return isPositive(this.t4RevenueGrowth);
    },
    trailingNegative() {
      return isNegative(this.t4RevenueGrowth);
    },
  },
  methods: {
    toMDY(dateString) {
      if (!dateString) return "";
      return "Entered " + dateStringToMDY(dateString);
    },
  },
};
</script>

<style lang="sass" scoped>
td
  text-align: right
  &.text-left
    text-align: left
  &.text-center
    text-align: center
.t4growth
  margin-left: 5px
  font-size: 0.8rem
</style>
