<template>
  <div class="person box" :draggable="true" @dragstart="dragPerson($event, person)">
    <div class="media">
      <div class="picture media-left" :class="{ founder: founder }">
        <a
          target="_blank"
          :href="pipedriveUrl"
          :class="{ disabled: !email }"
          @click.alt.prevent="fetch"
        >
          <img :src="imageUrl" class="avatar" />
        </a>
      </div>
      <div class="media-content">
        <div class="content">
          <h4 class="name" v-html="highlight(person.name || 'Unknown Name!')"></h4>
          <p
            class="title"
            :class="{ disabled: !title }"
            v-html="highlight(title || 'Unknown title')"
          ></p>

          <p class="email">
            <a :href="mailto" class="has-text-dark" :class="{ disabled: !email }">
              {{ email || "Email missing!" }}
            </a>
          </p>
          <a v-if="isSummary" class="load is-size-3" @click="loadFullPerson"> ... </a>
        </div>
      </div>
    </div>
    <ul v-if="!isSummary" class="person-profiles">
      <li v-for="profile in profiles" :key="profile.id">
        <a
          target="_blank"
          :href="person[profile.key]"
          :class="{ 'has-text-light': !person[profile.key] }"
        >
          <font-awesome-icon :icon="['fab', profile.icon]" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { personEmail, personPictureUrl } from "@/common/people";
import pipedrive from "@/common/pipedrive";
import clearbit from "@/common/clearbit";

export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
    highlightText: {
      type: String,
      required: false,
      default: "",
    },
    loadAllData: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["more-data", "update-profile"],
  data() {
    return {
      // avatarUrl: "",
      profiles: [
        {
          id: "linkedin",
          url: "https://linkedin.com/",
          icon: "linkedin",
          key: "fe4334fb04c344d189d510ecd25a347829b75aec",
        },
        {
          id: "github",
          url: "https://github.com/",
          icon: "github-square",
          key: "2247aa4e2ab22c1184c643052c33d62765950988",
        },
        {
          id: "twitter",
          url: "https://twitter.com/",
          icon: "twitter-square",
          key: "4b20e7ff30056ec7fb0700767706392a95ee1d97",
        },
      ],
    };
  },
  computed: {
    pipedriveUrl() {
      return pipedrive.web.personUrl(this.person.id);
    },
    isSummary() {
      // Person records can either be a full, detailed record returned by a
      // /person/$id query or in some cases will be just the summary fields
      // returned by a search e.g. /persons/search in which case it will have a result score
      return this.person.result_score;
    },
    founder() {
      const member_tag_id = 173;
      const founderKey = "0c74485a9aba936bac4606213d946ddd6cb64b71";
      const tags = this.person[founderKey];
      return tags && tags.includes(member_tag_id);
    },
    email() {
      if (this.isSummary) return this.person.emails.join(", ");
      return personEmail(this.person);
    },
    emails() {
      // Returns an array of available emails for either summary or full results
      if (typeof this.person.email === "object") {
        return this.person.email.map((e) => e.value);
      } else {
        return [this.person.email].concat(this.person.additional_emails);
      }
    },
    title() {
      return this.isSummary ? this.person.org_name : this.person.job_title;
    },
    mailto() {
      return this.email ? `mailto:${this.email}` : null;
    },
    imageUrl() {
      return personPictureUrl(this.person);
    },
  },
  mounted() {
    if (this.loadAllData && this.isSummary) {
      const delay = Math.floor(Math.random() * 5 * 1000);
      setTimeout(this.loadFullPerson, delay);
    }
  },
  methods: {
    highlight(text) {
      const substring = this.highlightText;
      var index = text.toLowerCase().indexOf(substring.toLowerCase());
      if (index >= 0) {
        return (
          text.substring(0, index) +
          "<span class='highlight'>" +
          text.substring(index, index + substring.length) +
          "</span>" +
          text.substring(index + substring.length)
        );
      }
      return text;
    },
    loadFullPerson() {
      // when we only have summary data for a person, this loads the full data
      // and emits it so the parent component can enrich the person
      pipedrive.api
        .get(`persons/${this.person.id}`)
        .then((response) => {
          const person = response.data.data;
          this.$emit("more-data", this.person.id, person);
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error(`Unable to load additional data for ${this.person.name}`);
        });
    },
    dragPerson(event, person) {
      const format = "application/json";
      const data = JSON.stringify(person);
      event.dataTransfer.setData(format, data);
    },
    fetch() {
      if (!this.email || !this.email.includes("@")) {
        return this.$toasted.error(`Unable to find email for ${this.person.name}`);
      }
      this.fetchProfile(this.email);
    },
    fetchProfile(email) {
      clearbit
        .enrich(email)
        .then((response) => {
          const person = response.data.person;
          if (!person) {
            return this.$toasted.info(`No data found for ${this.person.name} using '${email}'.`);
          }
          const updates = this._buildUpdate(person);
          this.$emit("update-profile", this.person.id, updates);
        })
        .catch((err) => this.$toasted.error(err.message));
    },
    _buildUpdate(person) {
      // Update various person values without overwriting existing values if present
      const updates = {};

      // Update all the social profiles
      this.profiles.map((profile) => {
        const currentValue = this.person[profile.key];
        const newValue = person[profile.id] ? person[profile.id].handle : null;
        if (!currentValue && newValue) {
          updates[profile.key] = `${profile.url}${newValue}`;
        }
      });

      // Update the bio
      const bioKey = "4d4fc23470ee0f1ea530c35bd6fc5258dc196dc8";
      updates[bioKey] = this.person[bioKey] ? this.person[bioKey] : person.bio;

      // Update the title
      const title = person.employment ? person.employment.title : null;
      updates.job_title = this.person.job_title ? this.person.job_title : title;

      // Mark as founder if title matches
      const founderTag = 173;
      const tagsKey = "0c74485a9aba936bac4606213d946ddd6cb64b71";
      const isFounder = (p) => (p[tagsKey] || "").includes(founderTag);

      if (!isFounder(this.person) && title && title.toLowerCase().includes("founder")) {
        updates[tagsKey] = [...this.person[tagsKey].split(","), founderTag].join(",");
      }
      // Save the best image link to a new avatarUrl property
      // this.avatarUrl = person.avatar;
      // console.log("Image: " + this.avatarUrl);
      return updates;
    },
    // saveImage(data) {
    //   if (!data) return;
    //   const params = { id: this.person.id };
    //   pipedrive
    //     .put(`persons/${this.person.id}/picture`, data, params)
    //     .then(response => {
    //       if (response.status == 200) this.$toasted.success("Saved profile image");
    //       else this.$toasted.error("Error saving profile image");
    //     })
    //     .catch(err => {
    //       console.log(err);
    //       this.$toasted.error(`Error saving profile image`);
    //     });
    // }
  },
};
</script>

<style lang="sass" scoped>
.person
  padding: 1rem
  position: relative
  overflow: hidden
  flex-grow: 1
  flex-shrink: 1
  flex-basis: 30%
  min-width: 15rem
  max-width: 18.5rem
  height: 6.2rem
.person:hover
  border-color: #dbdbdb
.media-content
  overflow: hidden
.content
  p
    font-size: small
  .name
    margin-bottom: 0.25rem
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    letter-spacing: -0.5px
  .title
    margin: 0.25rem 0 0.5rem 0
  .email
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
.founder .avatar
  border: 2px solid orange !important
.person-profiles
  position: absolute
  padding: 0.4rem 0.6rem 0 0.6rem
  background: #fffd
  border-radius: 5px
  right: -2rem
  top: 0
  transition: 0.2s
.person:hover
  .person-profiles
    right: 0
    transition: 0.1s
.load
  border-radius: 5px
  position: absolute
  right: -2rem
  bottom: 0
  transition: 0.2s
.person:hover
  .load
    right: 0.5rem
    transition:0.1s
.person-profiles li
  font-size: 1.4rem
  line-height: 1.8rem
  margin-left: 0.5rem
.disabled
  font-weight: 400
  font-style: italic
  opacity: 0.3
</style>
