<template>
  <a
    :href="href"
    :class="{ button: !inline, 'is-inline': inline, 'is-small': small }"
    target="_blank"
    class="to-drive"
    title="View in Google Drive"
  >
    <span class="icon">
      <img src="@/assets/images/googledrive_logo.png" />
    </span>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: "https://drive.google.com",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
a.to-drive
  img
    position: relative
    top: 0
    width: 18px
a.to-drive.is-small
  img
    top: 0
    width: 13px
a.to-drive.is-inline
  border: none
  display: inline-block !important
  opacity: 0.7 !important
  position: relative
  top: 4px
  left: -2px
  &:hover
    opacity: 1.0
</style>
