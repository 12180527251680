<template>
  <tr :key="file.id" :class="{ folder: isFolder }">
    <td class="filename">
      <a
        class="has-text-dark"
        @click.exact="open"
        @click.meta="openNew"
        @click.alt="$emit('selected', file.id, file.mimeType)"
      >
        <img :src="file.iconLink" />
        <span :class="{ 'is-loaded': !file.isFetching }" v-html="file.htmlName || file.name"></span>
      </a>
      <div v-if="showTags" class="tags are-small is-pulled-right">
        <template v-for="(value, key) in file.properties" :key="key">
          <span class="tag is-light">{{ key }} : {{ value }} </span>
        </template>
      </div>
    </td>
    <td v-if="showMetadata" class="metadata">{{ createdTime }}</td>
    <td v-if="showMetadata" class="metadata">{{ modifiedTime }}</td>
  </tr>
</template>

<script>
import { timeAgoInWords } from "@/utils/dateFormatter";

export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    showMetadata: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTags: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["clicked", "selected", "toggle"],
  data() {
    return {};
  },
  computed: {
    createdTime() {
      return timeAgoInWords(this.file.createdTime);
    },
    modifiedTime() {
      return timeAgoInWords(this.file.modifiedTime);
    },
    isFolder() {
      return this.file.mimeType === "application/vnd.google-apps.folder";
    },
  },
  methods: {
    open() {
      if (this.isFolder) {
        this.$emit("toggle");
      } else {
        this.$emit("clicked", this.file.id, this.file.mimeType, this.file.webViewLink);
      }
    },
    openNew() {
      window.open(this.file.webViewLink, "_blank");
    },
  },
};
</script>

<style lang="sass" scoped></style>
