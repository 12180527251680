const LOCALSTORAGE_PREFIX = "auger.noteEditor.";

export const clearNoteCache = function (key) {
  localStorage.removeItem(LOCALSTORAGE_PREFIX + key);
};

export const retrieveNoteCache = function (key) {
  return localStorage.getItem(LOCALSTORAGE_PREFIX + key);
};

export const setNoteCache = function (key, value) {
  return localStorage.setItem(LOCALSTORAGE_PREFIX + key, value);
};
