// We currently use auth0 for authentication and don't need to sign into google
// directly since the migration to use Google Identity Services (GIS). However,
// this is a working implementation of google sign-in based on GIS. I'm preserving
// the code in case we want to move away from Auth0 in the future.

import { reactive } from "vue";
import { google as googleConfig } from "@/common/config";
import { importJWK, jwtVerify } from "jose";
import axios from "axios";
import { ensureGISLoaded } from "./util";

const state = reactive({
  publicKey: null,
  user: null,
});

// fetch current public key in JWK format
export const fetchGooglePublicKey = async function () {
  try {
    const response = await axios.get("https://www.googleapis.com/oauth2/v3/certs");
    state.publicKey = response.data.keys;
  } catch (error) {
    throw "Unable to fetch current Google public keys";
  }
};

const handleSignin = async function (response) {
  console.log(response);
  const jwt = response.credential;
  const pubKey = await importJWK(state.publicKey[0]);
  const issuers = ["https://accounts.google.com", "accounts.google.com"];
  try {
    const { payload } = await jwtVerify(jwt, pubKey, { issuer: issuers });
    state.user = {
      email: payload.email,
      name: payload.name,
    };
    // TODO: put user state into local storage
  } catch (err) {
    throw "Unable to verify Google JWT";
  }
};

// set up the sign in client that manages google login and logout
export const initializeSignInClient = async function (google) {
  // TODO: if local storage knows about user, repopulate
  return google.accounts.id.initialize({
    client_id: googleConfig.clientId,
    auto_select: true,
    callback: handleSignin,
  });
};

// render the google login button inside of target parent element
export const renderLoginButton = function (el) {
  // opts to customize appearance
  // https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton
  const displayOptions = {
    size: "medium",
    // theme: "outline",
    // type: "standard",
    shape: "pill",
  };
  ensureGISLoaded().then((accounts) => accounts.id.renderButton(el, displayOptions));
};

export const logoutGoogleUser = function () {
  state.user = null;
  // TODO: clear access token from store
  // TODO: clear local storage for google user
  ensureGISLoaded().then((accounts) => accounts.id.disableAutoSelect());
};
