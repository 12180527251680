import DOMPurify from "dompurify";

// remove XSS threats
// https://github.com/cure53/DOMPurify
export const sanitizeXSS = function (html) {
  return DOMPurify.sanitize(html);
};

// return html with all tags removed
export const stripTags = function (html) {
  if (!html) return "";
  return html.replace(/<\/?[^>]+(>|$)/g, "").trim();
};

export default {
  sanitizeXSS,
  stripTags,
};
