<template>
  <div v-show="fund" class="fund">
    <section id="fund-header" class="section">
      <div class="level toolbar">
        <div class="level-left">
          <select-header
            :options="fundMap"
            value-key="id"
            title-key="name"
            :selected="id"
            @select="selectFund"
          />
        </div>
        <div class="level-right">
          <PipedriveButton v-show="fund.id" :href="organizationUrl" />
        </div>
      </div>

      <div class="level stats">
        <div class="level-right">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Total Committed</p>
              <p class="title has-text">${{ (totalCommitted / 1e6).toFixed(1) }}m</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Total Invested</p>
              <p class="title has-text">${{ (totalInvested / 1e6).toFixed(1) }}m</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Avg. Investment</p>
              <p v-if="averageInvested > 0" class="title">
                {{ $filters.currencyRounded(averageInvested / 1e3) }}k
              </p>
              <p v-else class="title">$0k</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Investments</p>
              <p class="title">{{ investments?.length || 0 }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Companies</p>
              <p class="title">{{ companies?.length || 0 }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="fund-tabs" class="section">
      <div class="tabs">
        <ul>
          <li v-for="tab in tabs" :key="tab.key" :class="{ 'is-active': selectedTab === tab.key }">
            <a @click="selectedTab = tab.key">{{ tab.title }}</a>
          </li>
        </ul>
      </div>

      <div v-show="selectedTab == 'portfolio' || selectedTab === 'all'" class="tab">
        <FundPortfolio :companies="companies" />
      </div>

      <div v-show="selectedTab == 'deals' || selectedTab === 'all'" class="tab">
        <FundInvestments :investments="investments" />
      </div>

      <div v-show="selectedTab === 'lps' || selectedTab === 'all'" class="tab">
        <FundCommitments :commitments="commitments" />
      </div>

      <!-- <div class="tab" v-show="selectedTab === 'ppl' || selectedTab === 'all'">
        <FundInvestors :investors="investors" />
      </div> -->

      <div v-show="selectedTab === 'program' || selectedTab === 'all'" class="tab">
        <FundProgramHistory :investments="investments" />
      </div>

      <div v-show="selectedTab === 'pipeline' || selectedTab === 'all'" class="tab">
        <FundPipeline :pipedrive-id="pipedriveId" />
      </div>
    </section>

    <br />
  </div>
</template>

<script>
import PipedriveButton from "@/components/PipedriveButton";
import SelectHeader from "@/components/SelectHeader";
import FundPortfolio from "@/components/FundPortfolio";
import FundInvestments from "@/components/FundInvestments";
import FundCommitments from "@/components/FundCommitments";
// import FundInvestors from "@/components/FundInvestors";
import FundProgramHistory from "@/components/FundProgramHistory";
import FundPipeline from "@/components/FundPipeline";

import { FUNDS } from "@/common/funds";
import pipedrive from "@/common/pipedrive";
import { deals, people, organizations } from "@/common/pipedrive/resources";

export default {
  components: {
    PipedriveButton,
    SelectHeader,
    FundPortfolio,
    FundInvestments,
    FundCommitments,
    // FundInvestors,
    FundProgramHistory,
    FundPipeline,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      fundKey: "c9ee69a1f5d4ef7f1f842834a80ce4cd91328ac2",
      fund: {},
      funds: FUNDS,
      allCompanies: [],
      allInvestments: [],
      allCommitments: [],
      allInvestors: [],
      allDeals: [],
      tabs: [
        { key: "portfolio", title: "Portfolio" },
        { key: "deals", title: "Investments" },
        { key: "lps", title: "Commitments" },
        // { key: "ppl", title: "Investors" },
        { key: "program", title: "Program Equity" },
        // { key: "reports", title: "Reporting" },
        { key: "pipeline", title: "Pipeline" },
        { key: "all", title: "Show All" },
      ],
      selectedTab: "portfolio",
    };
  },
  computed: {
    fundMap() {
      return this.funds.map((f) => Object.assign({ id: f.ref }, f));
    },
    pipedriveId() {
      return this.funds.find((f) => f.ref === this.id)?.pipedrive_id || 0;
    },
    investments() {
      if (!this.fund || this.id === "all") return this.allInvestments;
      return this.allInvestments.filter((i) => i[this.fundKey]?.value == this.fund.id);
    },
    commitments() {
      if (!this.fund || this.id === "all") return this.allCommitments;
      let id = this.fund.id || this.fund.pipedrive_id;
      return this.allCommitments.filter((i) => i[this.fundKey]?.value == id);
    },
    companies() {
      // Create an array of unique org ids from the current fund's investments
      let ids = [...new Set(this.investments.map((i) => i.org_id.value))];
      // Create array of companies from the ids in the list
      return this.allCompanies.filter((org) => ids.includes(org.id));
    },
    deals() {
      if (!this.fund || this.id === "all") return this.allDeals;
      let id = this.fund.id || this.fund.pipedrive_id;
      console.log("filter deals");
      return this.allDeals.filter((d) => d[this.fundKey] == id);
    },
    investors() {
      // Create an array of unique person ids from the current fund's commitments
      let ids = [...new Set(this.commitments.flatMap((deal) => this.personIds(deal)))];
      return this.allInvestors.filter((person) => ids.includes(person.id));
    },
    totalCommitted() {
      // const key = "49f935d19c9d29dc1ab5552fad455069b32b3017"; // Amount invested field
      return this.commitments.reduce((sum, investment) => (sum += investment.value), 0);
    },
    totalInvested() {
      const amount = "49f935d19c9d29dc1ab5552fad455069b32b3017"; // Amount invested field
      const equity = "fdef8d9342497889c83924ef6cb4b282d7dbb9d4"; // Program equity field
      // If the program equity field isn't set, defer to the value field for amount invested
      return this.investments.reduce((sum, i) => (sum += i[equity] ? i[amount] : i.value), 0);
    },
    averageInvested() {
      return this.investments.length == 0 ? 0 : this.totalInvested / this.investments.length;
    },
    organizationUrl() {
      if (!this.fund) return;
      return pipedrive.web.organizationUrl(this.fund.id);
    },
    summary() {
      return this.fund["426a3fe18ecb9344e548ee31614be1dd905d1920"];
    },
    driveFolder() {
      return this.fund["fe4bc918a3bc8bedd077f9896ffd23781317d936"];
    },
  },
  watch: {
    id() {
      this.fetchFund();
    },
  },
  created() {
    this.fetchFund();
    this.fetchCompanies();
    this.fetchInvestments();
    this.fetchCommitments();
    this.fetchDeals();
  },
  mounted() {
    this._bindKeys();
  },
  beforeUnmount() {
    this._unbindKeys();
  },
  methods: {
    selectFund(id) {
      if (this.id === id) return;
      this.$router.push({ name: "fund-show", params: { id: id } });
    },
    fetchFund() {
      // Get the fund from the local list of funds - TODO: replace this with fetched dynamic list
      const fund = this.funds.find((f) => f.ref === this.id);

      if (!fund || !fund.pipedrive_id) {
        // No valid pipedrive id or id == 0, default to all funds
        this.fund = this.funds[0];
      } else {
        // Otherwise search for the specfic fund
        organizations
          .byId(fund.pipedrive_id)
          .then((response) => (this.fund = response))
          .catch(() => this.$toasted.error(`Unable to find fund with id ${fund.pipedrive_id}`));
      }
    },
    fetchInvestments() {
      // Fetch all won members deals for all funds and then use
      // a computed property for the specific fund that is selected
      deals
        .byFilter(170, { limit: 1000, status: "won", sort: "won_time DESC" })
        .then((response) => {
          this.allInvestments = response;

          // Raise a warning if any investments aren't assigned to a fund
          const missingFunds = this.investments.filter((i) => !i[this.fundKey]);
          if (missingFunds.length === 0) return;
          this.$toasted.error(
            missingFunds.length === 1
              ? `${missingFunds[0].title} missing fund`
              : `${missingFunds.length} investments missing funds`
          );
        })
        .catch(() => this.$toasted.error("Can't fetch investments"));
    },
    fetchCommitments() {
      // Fetches all fund commitments
      // N.B. We can't use the pipeline endpoint as in the fetchDeals method
      // because not all historic deals were in that pipeline
      deals
        .byFilter(234, { limit: 1000, sort: "title ASC, value DESC" })
        .then((response) => {
          this.allCommitments = response;

          // Raise a warning if any commitments aren't found and jump to the pipeline tab
          if (this.commitments.length === 0) {
            this.$toasted.info(`No commitments found for ${this.fund.name}`);
            this.selectedTab = "pipeline";
          }

          this.fetchInvestors();
        })
        .catch(() => this.$toasted.error(`Unable to fetch ${this.fund.name} commitments`));
    },
    fetchDeals() {
      // Fetch all deals in the LP pipeline regardless of status
      deals
        .inPipeline(17, { limit: 1000 })
        .then((response) => (this.allDeals = response))
        .catch(() => this.$toasted.error("Unable to fetch LP pipeline deals"));
    },
    fetchInvestors() {
      // TODO: Fetch the participants from every deal/commitment
      let allIds = this.allCommitments.flatMap((deal) => this.personIds(deal));

      // Remove dupes
      let ids = [...new Set(allIds)];

      // Get the investors details
      let sortByName = (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0);
      people.byIds(ids).then((result) => (this.allInvestors = result.sort(sortByName)));
    },
    personIds(deal) {
      // Given a fund deal (commitment) return a list of ids of the people associated
      // with the deal.  Because all participants aren't returned automatically we should
      // fetch the people with another call to PD. Instead, for the time being
      // just collate the persons directly connected to the deal (not all
      // participants)

      const financeContactKey = "618c26e9fab7947240d2ca50188f8c00ae618cd6";
      let ids = deal.person_id ? [deal.person_id.value] : [];
      if (deal[financeContactKey]) ids.push(deal[financeContactKey].value);
      return ids;
    },
    fetchCompanies() {
      // Fetch companies from all funds and filter with computed property
      organizations
        .byFilter(278, { sort: "name ASC" })
        .then((response) => (this.allCompanies = response))
        .catch(() => this.$toasted.error(`Unable to fetch ${this.fund.name} portfolio companies`));
    },
    selectTab(key) {
      switch (key) {
        case "P":
          return (this.selectedTab = this.selectedTab == "portfolio" ? "program" : "portfolio");
        case "I":
          return (this.selectedTab = this.selectedTab == "deals" ? "ppl" : "deals");
        case "C":
          return (this.selectedTab = "lps");
        case "A":
          return (this.selectedTab = "all");
        default:
          break;
      }
    },
    _bindKeys() {
      if (!this.$mousetrap) return;
      ["P", "I", "C", "A"].map((key) => this.$mousetrap.bind(key, () => this.selectTab(key)));
    },
    _unbindKeys() {
      if (!this.$mousetrap) return;
      ["P", "I", "E", "A"].forEach((k) => this.$mousetrap.unbind(k));
    },
  },
};
</script>

<style lang="sass">
#fund-tabs
  .tabs
    margin-bottom: 0
  ul li:last-child
      margin-left: auto
  .tab section
    padding: 1em 0em
  .tabs a
    font-weight: 400
    padding-left: 0.5rem
#fund-header
  .level
    margin-bottom: 0
  .level.stats
    margin-top: 0.3rem
  .level-item
    margin-right: 1.5rem
    .heading
      color: #7a7a7a
      animation: fadein 2s
    .title
      font-family: $family-secondary
      font-weight: 300
table
  font-size: 0.9rem
</style>
