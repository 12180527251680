<template>
  <div class="member-add modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add New Member</p>
      </header>
      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input v-model="member.name" class="input" type="text" />
            </div>
          </div>
          <div class="field">
            <label class="label">Member Stage</label>
            <div class="control select">
              <select v-model="member.member_stage_id">
                <option value="" disabled>Please select one</option>
                <option v-for="stage in stages" :key="stage.id" :value="stage.id">
                  {{ $filters.capitalize(stage.name) }}: {{ stage.description }}
                </option>
              </select>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Go To Market</label>
                <div class="control select">
                  <select v-model="member.go_to_market_id">
                    <option v-for="gtm in goToMarkets" :key="gtm.id" :value="gtm.id">
                      {{ gtm.full_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <label class="label">Founded</label>
                  <div class="control">
                    <input
                      v-model="member.founded_on"
                      class="input founded-on"
                      type="text"
                      placeholder="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <label class="label checkbox">
                    <input v-model="member.fundraising" type="checkbox" size="4" /> Actively
                    fundraising
                  </label>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Company Stage</label>
                <div class="control select">
                  <select v-model="member.company_stage_id">
                    <option v-for="stage in companyStages" :key="stage.id" :value="stage.id">
                      {{ $filters.capitalize(stage.name) }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <label class="label">Entered Heavybit</label>
                  <div class="control">
                    <input
                      v-model="member.entered_heavybit_on"
                      class="input entered-heavybit-on"
                      type="text"
                      placeholder="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <label class="label is-inline-block">Pipedrive ID</label>
                  &ndash;
                  {{ member.pipedrive_id }}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click.once="createMember">Create Member</button>
        <button class="button" @click="cancelAdd">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import clone from "lodash/clone";
import pick from "lodash/pick";
import metricSet from "@/common/metricSet";
import queries from "@/common/queries";
import mutations from "@/common/mutations";

export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    addProps: {
      type: Object,
      required: true,
    },
  },
  emits: ["close-add"],
  data() {
    return {
      member: {},
      goToMarkets: [],
      stages: [],
      companyStages: [],
      exit_types: [],
    };
  },
  apollo: {
    // TODO: could probably do all these queries at once
    companyStages: {
      query: queries.companyStages,
    },
    exitTypes: {
      query: queries.exitTypes,
    },
    stages: {
      query: queries.memberStages,
    },
    goToMarkets: {
      query: queries.goToMarkets,
    },
    metrics: {
      query: queries.metrics,
    },
  },
  computed: {
    insertMetrics() {
      if (!this.metrics.length) return [];
      const gtm = this.goToMarkets.find((g) => g.id === this.member.go_to_market_id);
      const metricsToAdd = metricSet.defaultForGTM(gtm && gtm.name);
      const selectedMetrics = this.metrics.filter((m) => metricsToAdd.includes(m.name));
      return selectedMetrics.map((metric) => ({ metric_id: metric.id }));
    },
    insertProps() {
      let props = pick(this.member, [
        "name",
        "fundraising",
        "founded_on",
        "entered_heavybit_on",
        "pipedrive_id",
        "go_to_market_id",
        "member_stage_id",
        "company_stage_id",
      ]);
      props["members_metrics"] = {
        data: this.insertMetrics,
      };
      return props;
    },
  },
  watch: {
    active() {
      this.member = clone(this.addProps);
    },
  },
  methods: {
    cancelAdd(event) {
      this.$emit("close-add");
      event.preventDefault();
    },
    createMember() {
      this.$apollo
        .mutate({
          mutation: mutations.createMember,
          variables: {
            objects: [this.insertProps],
          },
        })
        .then((result) => {
          let new_member_id = result.data.insert_members.returning[0].id;
          this.$router.push({ name: "member-show", params: { id: new_member_id } });
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="sass" scoped></style>
