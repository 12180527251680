<template>
  <section class="company-list section">
    <div id="header-row" class="level">
      <div class="level-left">
        <h1 class="is-size-2 level-item">Companies</h1>
      </div>
      <div class="level-right">
        <div class="company-search level-item">
          <div class="field">
            <p class="control has-icons-right is-medium" :class="{ 'is-loading': loading }">
              <input
                id="search-field"
                v-model="filter"
                type="text"
                placeholder="Search for a company"
                class="input is-medium"
                @keyup="updateSearch"
              />
              <span v-show="!loading" class="icon is-right"
                ><font-awesome-icon icon="search"
              /></span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-show="companies.length > 0" class="results">
      <ul>
        <li v-for="(company, index) in companies" :key="index" class="company">
          <router-link
            :to="{ name: 'company-show', params: { id: company.id } }"
            class="is-size-4 has-text-primary"
          >
            {{ company.name }}
          </router-link>
          <PipedriveButton :href="pipedriveUrl(company.id)" inline />
        </li>
      </ul>
    </div>
    <div v-show="companies.length === 0" class="level no-results">
      <p class="level-item">
        <span v-show="filter.length === 0">Start a search...</span>
        <span v-show="filter.length === 1">Please use a search string of two chars or more.</span>
        <span v-show="filter.length > 1 && !loading">No companies found for "{{ filter }}".</span>
      </p>
    </div>
  </section>
</template>

<script>
import PipedriveButton from "@/components/PipedriveButton.vue";
import pipedrive from "@/common/pipedrive";
import { organizations } from "@/common/pipedrive/resources";
import debounce from "lodash/debounce";

export default {
  components: {
    PipedriveButton,
  },
  props: {
    savedFilter: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filter: "",
      loading: false,
      companies: [],
    };
  },
  created() {
    this.debouncedSearch = debounce(() => {
      this._search();
      // update query string & browser history
      this.$router.replace({ query: { filter: this.filter } });
    }, 500);
  },
  mounted() {
    // get initial filter from query string
    this.filter = this.savedFilter;
    this._search();
    document.getElementById("search-field").focus();
  },
  methods: {
    pipedriveUrl(id) {
      return pipedrive.web.organizationUrl(id);
    },
    updateSearch() {
      this.loading = true;
      this.debouncedSearch();
    },
    async _search() {
      let term = this.filter;
      if (!term || term.length < 2) {
        this._resetSearch();
        return;
      }

      // TODO: handle failures with better visual feedback?
      this.loading = true;
      this.companies = await organizations.byName(term);
      this.loading = false;
    },
    _resetSearch() {
      this.companies = [];
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
section
  min-height: 600px
#header-row
  margin-bottom: 0
li.company
  padding: 0.5rem 0.75rem
.no-results
  font-size: 1.75rem
  font-weight: 300
  margin-top: 100px
</style>
