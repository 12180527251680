<template>
  <section class="section">
    <h1 class="is-size-3">Settings</h1>
    <div class="columns">
      <div class="column">
        <h2 class="is-size-4 mb-2">Personal Details</h2>
        <form>
          <div class="field">
            <label class="label">Your name</label>
            <div class="control">
              <input ref="name" v-model="name" type="text" class="input" />
            </div>
          </div>

          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input ref="email" v-model="email" type="text" class="input is-static" />
            </div>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div class="control">
              <button
                class="password-reset button is-small is-light"
                @click.prevent="sendPasswordReset"
              >
                <span class="icon is-small"><font-awesome-icon icon="envelope" /></span>
                <span>Send password reset email</span>
              </button>
            </div>
          </div>

          <div class="control update-button">
            <button
              ref="updateButton"
              class="button is-outlined is-primary"
              @click.prevent="updateSettings"
            >
              <span>Update Settings</span>
            </button>
          </div>
        </form>
      </div>

      <div class="column">
        <h2 class="is-size-4 mb-2">Personal API Credentials</h2>
        <form>
          <div class="field">
            <label class="label">Pipedrive API token</label>
            <div class="control">
              <input ref="pd-token" v-model="pipedriveToken" type="text" class="input" />
            </div>
            <p class="help">
              You can
              <a target="_blank" href="https://heavybit.pipedrive.com/settings/api">
                find your token here
              </a>
            </p>
          </div>
          <div class="field mt-3">
            <label class="label">Fireflies API key</label>
            <div class="control">
              <input ref="ff-token" v-model="firefliesToken" type="text" class="input" />
            </div>
            <p class="help">
              Find your key in
              <a target="_blank" href="https://app.fireflies.ai/settings"> Account Settings </a>
              → Developer Settings
            </p>
          </div>
        </form>

        <hr />
        <h2 class="is-size-4">Google Account</h2>
        <div class="google-buttons">
          <button class="button" @click="authorizeGoogle">
            <template v-if="authorizationNeeded">Authorize</template>
            <template v-else>Reauthorize</template>
            Google Access
          </button>
          <div v-if="!authorizationNeeded" class="google-notes help">
            <p class="mt-2">Having trouble accessing some Google resources? Try reauthorizing.</p>
            <p class="mt-2">
              If needed you can also <a @click="revokeGoogle">revoke Google access</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { revokeGoogleAuthorization, startGoogleAuthorization } from "@/common/google/authorization";
import mutations from "@/common/mutations";
import { useGoogleStore } from "@/stores/google";
import { useUserStore } from "@/stores/user";

export default {
  setup() {
    const googleStore = useGoogleStore();
    const userStore = useUserStore();
    return { googleStore, userStore };
  },
  data() {
    return {
      name: "",
      firefliesToken: "",
      pipedriveToken: "",
      updating: false,
    };
  },
  computed: {
    authorizationNeeded() {
      return this.googleStore.authorizationNeeded;
    },
    email() {
      return this.userStore.email;
    },
    storeUser() {
      return this.useStore.user;
    },
  },
  watch: {
    email() {
      // if email has changed, the store has received an update on user
      // data, so resynch. on direct navigation to the settings page
      // we may not have queried user data yet, so this will be invoked
      // in that case.
      this._syncData();
    },
  },
  mounted() {
    this._syncData();
  },
  methods: {
    authorizeGoogle() {
      startGoogleAuthorization();
    },
    revokeGoogle() {
      revokeGoogleAuthorization()
        .then(() => {
          this.googleStore.resetGoogle();
          this.$toasted.success("Google access revoked.");
        })
        .catch(() => this.$toasted.error("An error occured while revoking authorization"));
    },
    sendPasswordReset() {
      this.$auth
        .changePassword(this.email)
        .then(() => {
          this.$toasted.success("Your password reset email has been sent.");
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error(`An error occurred: ${err}`);
        });
    },
    updateSettings() {
      this.updating = true;
      this.$refs.updateButton.blur();
      this.$apollo
        .mutate({
          mutation: mutations.updateUser,
          variables: {
            id: this.userStore.userId,
            changes: {
              name: this.name,
              fireflies_token: this.firefliesToken,
              pipedrive_token: this.pipedriveToken,
            },
          },
        })
        .then(() => {
          this.userStore.updateProperties({
            name: this.name,
            fireflies_token: this.firefliesToken,
            pipedrive_token: this.pipedriveToken,
          });
          this.$toasted.success("Your settings have been updated.");
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.error("Something went wrong. Check console for more.");
        })
        .finally(() => (this.updating = false));
    },
    _syncData() {
      this.name = this.userStore.name;
      this.firefliesToken = this.userStore.firefliesToken;
      this.pipedriveToken = this.userStore.pipedriveToken;
    },
  },
};
</script>

<style lang="sass" scoped>
.google-buttons
  display: flex
  flex-direction: column
  .button
    margin-top: $space-sm
    width: 250px
.update-button
  margin-top: $space-lg
</style>
