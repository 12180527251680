<template>
  <div class="event-date box" :class="{ small }">
    <div v-if="small" class="event-month">{{ dateFormat(date, "MMM") }}</div>
    <div v-else class="event-month">
      {{ dateFormat(date, "MMM") }} {{ dateFormat(date, "yyyy") }}
    </div>
    <div class="event-day">{{ dateFormat(date, "do") }}</div>
    <div v-if="showTime">
      <div v-if="start && end" class="event-time">
        {{ dateFormat(start, "h:mmaaa") }} - {{ dateFormat(end, "h:mm") }}
      </div>
      <div v-else-if="hasTime" class="event-time">{{ dateFormat(date, "h:mmaaa") }}</div>
      <div v-else class="is-size-7">All day</div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/common/filters";
import { today, isDateString } from "@/utils/date";

export default {
  props: {
    date: {
      type: String,
      required: false,
      default: () => today(),
    },
    start: {
      type: String,
      default: "",
      required: false,
    },
    end: {
      type: String,
      default: "",
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasTime() {
      return !isDateString(this.date);
    },
  },
  methods: {
    dateFormat,
  },
};
</script>

<style lang="sass" scoped>
.box.event-date
  font-family: $family-secondary
  color: #7a7a7a
  padding: 0rem 0.5rem 0.3rem 0.5rem
  margin-bottom: 0.2rem
  min-width: 4rem
  text-align: center !important
  .event-day
    font-size: 1.8rem
    line-height: 2.6rem
    padding-bottom: 0.2rem
  .event-month
    font-size: 0.9rem
    font-weight: 600
    background: #ea5f5fdb
    color: white
    margin: 0rem -0.5rem -0.5rem -0.5rem
    border-radius: 5px 5px 0px 0px
    margin-bottom: 0.4rem
    min-width: 5.5rem
  &.small
    padding: 0
    padding-bottom: 0.2rem
    margin: 0
    min-width: 3.5rem
    .event-day
      font-size: 1.2rem !important
      line-height: 2.2rem
      margin: 0
      padding: 0
      margin-bottom: -0.3rem
    .event-month
      font-size: 0.8rem !important
      font-weight: 600
      background: #ea5f5fdb
      color: white
      margin: 0
      border-radius: 5px 5px 0px 0px
      margin-bottom: -0.1rem
</style>
