<template>
  <section class="fund-program section">
    <div class="toolbar">
      <h2 class="title">Program Equity</h2>
      <div class="tools">
        <div class="field">
          <label for="allInvestments"> Show All Investments: </label>
          <input id="allInvestments" v-model="showAll" type="checkbox" />
        </div>

        <div class="field">
          <label for="reverseOrder"> Reverse Order: </label>
          <input id="reverseOrder" v-model="reverseOrder" type="checkbox" />
        </div>

        <button class="button is-small" @click="download">Download</button>
      </div>
    </div>

    <table v-if="programInvestments.length == 0" id="program" class="table is-fullwidth">
      <thead>
        <tr>
          <th>Company</th>
          <th v-for="quarter in quarters" :key="quarter.name">
            {{ quarter.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="12" class="no-equity">No program equity found</td>
        </tr>
      </tbody>
    </table>

    <div v-else class="table-scrollable">
      <div class="shadow-scroller">
        <table id="program" class="table">
          <thead>
            <tr>
              <th>Company</th>
              <th v-for="quarter in quarters" :key="quarter.name">
                {{ quarter.name }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="investment in programInvestments" :key="investment.id">
              <td>
                <a
                  target="_blank"
                  class="has-text-dark has-text-weight-semibold"
                  :href="pipedriveUrl(investment.org_id.value)"
                  >{{ investment.org_id.name }}</a
                >
              </td>
              <td v-for="quarter in quarters" :key="quarter.name">
                <span v-if="new Date(investment.won_time.substring(0, 10)) <= quarter.end">
                  {{ $filters.currencyRounded(programValue(investment)) }}
                </span>
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <th class="investment-title">Total</th>
              <th v-for="quarter in quarters" :key="quarter.name">
                {{ $filters.currencyRounded(quarter.programValue) }}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { downloadCSVFromTable } from "@/utils/csv";
import { startOfQuarter, endOfQuarter, addMonths, format as dateFormat } from "date-fns";
import pipedrive from "@/common/pipedrive";

export default {
  props: {
    investments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showAll: false,
      reverseOrder: true,
    };
  },
  computed: {
    programInvestments() {
      if (!this.investments) return [];
      let investments = Array.from(this.investments);
      if (!this.showAll) investments = investments.filter((i) => this.programEquity(i) > 0);
      if (this.reverseOrder) investments.reverse();
      return investments;
    },
    quarters() {
      if (this.investments == undefined || this.investments.length === 0) return [];

      // Get the first and last dates of the investments
      let firstDate = new Date(this.investments[0].won_time.substring(0, 10));
      let lastDate = firstDate;
      this.investments.map(function (investment) {
        const won = investment.won_time.substring(0, 10);
        const date = new Date(won);
        lastDate = date > lastDate ? date : lastDate;
        firstDate = date < firstDate ? date : firstDate;
      });

      // Construct an array of quarters from first to last date
      let start = startOfQuarter(firstDate);
      const quarters = [];
      while (start < lastDate) {
        quarters.push({
          name: dateFormat(start, "'Q'Q yyyy"),
          start: start,
          end: endOfQuarter(start),
        });
        start = addMonths(start, 3);
      }

      // Calculate cumulative program equity value
      for (let index = 0; index < quarters.length; index++) {
        const q = quarters[index];
        q.programValue = this.investments.reduce(
          (v, i) =>
            (v += new Date(i.won_time.substring(0, 10)) <= q.end ? this.programValue(i) : 0),
          0
        );
      }
      return quarters;
    },
  },
  methods: {
    download() {
      downloadCSVFromTable("program");
    },
    pipedriveUrl(id) {
      return pipedrive.web.organizationUrl(id);
    },
    programEquity(investment) {
      return investment["fdef8d9342497889c83924ef6cb4b282d7dbb9d4"];
    },
    programValue(investment) {
      return investment.value - investment["49f935d19c9d29dc1ab5552fad455069b32b3017"];
    },
  },
};
</script>

<style lang="sass" scoped>
table#program
  // margin-top:1rem
  font-size: 0.9rem
  td, th
    text-align: right !important
    width: 12rem
    padding-top: 0.3rem
    padding-bottom: 0.3rem
    padding-left:0
    vertical-align: top
  tr *:first-child
    text-align: left !important
  td.no-equity
    font-weight: 600
    padding-top: 0.9rem
    padding-bottom: 0.9rem
    text-align: center !important
    color: lightgray
</style>
