<template>
  <footer class="footer has-text-centered">
    &copy; 2019-{{ currentYear }} Heavybit, Inc. Internal use only.
  </footer>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="sass" scoped>
footer
  box-shadow: 0 50vh 0 50vh #fafafa
</style>
