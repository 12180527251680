import { downloadFile } from "./download";

// Read data from table with id <tableId> and download as CSV file
export const downloadCSVFromTable = function (tableId, separator = ",") {
  // Get the table into a CSV formatted string
  const csv = convertTableToCSV(tableId, separator);

  // Set the destination filename for the download
  const dateStr = new Date().toISOString().split("T")[0];
  const filename = `${dateStr}-${tableId}-table-export.csv`;

  downloadFile(filename, "data:text/csv;charset=utf-8", csv);
};

// Convert a table referenced by it's HTML id into a CSV formatted string
export const convertTableToCSV = function (tableId, separator = ",") {
  // Select rows from table
  const rows = document.querySelectorAll("table#" + tableId + " tr");

  // Construct CSV
  let lines = [];
  let rowspan = 0;
  for (let i = 0; i < rows.length; i++) {
    let row = [];
    const cols = rows[i].querySelectorAll("td, th");

    // Insert empty leading cells if a prior cell in the first column had rowspan set
    if (rowspan > 0) {
      row.push('""');
      rowspan--;
    } else if (cols[0]) {
      rowspan = cols[0].getAttribute("rowspan") || 1;
      rowspan--;
    }

    for (let j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      let data = cols[j].innerText
        .replace(/(\n\n)/, "; ")
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      // console.log(cols[j].innerText);

      // Escape double-quote with double-double-quote
      // see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv
      data = data.replace(/"/g, '""');

      // Push escaped string
      row.push('"' + data + '"');
    }
    lines.push(row.join(separator));
  }

  // Join lines into single string
  return lines.join("\n");
};
