<template>
  <div id="funnel" class="column has-text-centered">
    <FunnelElement
      v-for="(metric, index) in sequence"
      :key="index"
      :measurements="forMetric(metric)"
      :units="metric"
      :conversion="conversionFor(metric)"
      :final="metric === sequenceEnd"
    />
  </div>
</template>

<script>
import FunnelElement from "@/components/FunnelElement.vue";
import { lastValues } from "@/utils/measurements";

export default {
  components: {
    FunnelElement,
  },
  props: {
    measurements: {
      type: Array,
      default: () => [],
    },
    sequence: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    sequenceEnd() {
      return this.sequence.slice(-1)[0];
    },
  },
  methods: {
    conversionFor(metricName) {
      const index = this.sequence.indexOf(metricName);
      const next = this.sequence[index + 1];
      if (!next) return null;

      const numerator = this.lastValue(next);
      const divisor = this.lastValue(metricName);
      if (!numerator || !divisor) return null;

      const conversion = parseFloat(numerator) / parseFloat(divisor);
      return isFinite(conversion) ? conversion * 100.0 : null;
    },
    forMetric(metricName) {
      return lastValues(this.measurements, metricName, 5);
    },
    lastValue(metricName) {
      const last = lastValues(this.measurements, metricName, 1)[0];
      return last && last.value ? last.value : null;
    },
  },
};
</script>

<style lang="sass">
#funnel
  display: flex
  flex-direction: column
</style>
