<template>
  <div class="member-edit modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Manage Member</p>
      </header>

      <section class="modal-card-body">
        <form>
          <div class="field">
            <label class="label">Name</label>
            <div class="control"><input v-model="member.name" class="input" type="text" /></div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Member Stage</label>
                <div class="control select">
                  <select v-model="member.member_stage_id">
                    <option v-for="stage in stages" :key="stage.id" :value="stage.id">
                      {{ $filters.capitalize(stage.name) }}
                    </option>
                  </select>
                </div>
                <p class="help">
                  <span v-if="member.member_stage_id">
                    {{ stages.find((s) => s.id == member.member_stage_id).description }}
                  </span>
                  &nbsp;
                </p>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Date Founded</label>
                  <div class="control">
                    <input
                      v-model="member.founded_on"
                      class="input"
                      type="text"
                      placeholder="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <label class="label">Date Joined Heavybit</label>
                  <div class="control">
                    <input
                      v-model="member.entered_heavybit_on"
                      class="input"
                      type="text"
                      placeholder="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Company Stage</label>
                <div class="control select">
                  <select v-model="member.company_stage_id">
                    <option v-for="stage in companyStages" :key="stage.id" :value="stage.id">
                      {{ $filters.capitalize(stage.name) }}
                    </option>
                  </select>
                </div>
                <p class="help">&nbsp;</p>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label is-inline-block">Pipedrive ID</label>
                  <input v-model="member.pipedrive_id" class="input is-disabled" type="text" />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <label class="label is-inline-block">Carta ID</label>
                  <input v-model="member.carta_id" class="input" type="text" />
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Go To Market</label>
            <div class="control select">
              <select v-model="member.go_to_market_id">
                <option v-for="gtm in goToMarkets" :key="gtm.id" :value="gtm.id">
                  {{ gtm.full_name }}
                </option>
              </select>
            </div>
          </div>

          <div class="field">
            <label class="label">Funnel</label>
            <div class="control">
              <input
                v-model="funnelString"
                class="input is-fullwidth"
                type="text"
                :placeholder="funnelPlaceholder"
              />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <label class="label checkbox">
                <input v-model="member.fundraising" type="checkbox" size="4" />
                Actively fundraising
              </label>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="saveEdits">Save changes</button>
        <button class="button" @click="cancelEdit">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import clone from "lodash/clone";
import queries from "@/common/queries";
import mutations from "@/common/mutations";
import { useMemberStore } from "@/stores/member";

export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    editProps: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    defaultFunnel: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close-edit"],
  setup() {
    const memberStore = useMemberStore();
    return { memberStore };
  },
  data() {
    return {
      goToMarkets: [],
      member: {},
      stages: [],
      companyStages: [],
      exit_types: [],
    };
  },
  apollo: {
    // TODO: could probably do all these queries at once
    companyStages: {
      query: queries.companyStages,
    },
    exitTypes: {
      query: queries.exitTypes,
    },
    stages: {
      query: queries.memberStages,
    },
    goToMarkets: {
      query: queries.goToMarkets,
    },
  },
  computed: {
    funnelPlaceholder() {
      if (!this.defaultFunnel) return "";
      return this.defaultFunnel.join(", ");
    },
    funnelString: {
      get() {
        if (!this.member.funnel) return "";

        return this.member.funnel.join(", ");
      },
      set(newVal) {
        const trimmed = newVal.trim();
        if (!trimmed) {
          this.member.funnel = null;
        } else {
          this.member.funnel = trimmed.split(",").map((item) => item.trim());
        }
      },
    },
  },
  watch: {
    active() {
      this.member = clone(this.editProps);
    },
  },
  methods: {
    cancelEdit(event) {
      this.$emit("close-edit");
      event.preventDefault();
    },
    saveEdits() {
      this.$apollo
        .mutate({
          mutation: mutations.updateMember,
          variables: {
            id: Number(this.id),
            changes: this.member,
          },
        })
        .then(() => {
          this.memberStore.triggerRefresh();
          this.$emit("close-edit");
        });
    },
  },
};
</script>

<style lang="sass" scoped></style>
