<template>
  <div v-click-outside="closeAutocomplete" class="autocomplete">
    <input
      ref="searchField"
      v-model="searchString"
      class="autocomplete-input input"
      :class="sizeClass"
      type="text"
      :placeholder="placeholder"
      @input="updateSearch"
      @keyup.esc.exact="clear"
      @keydown.down="moveDown"
      @keydown.up="moveUp"
      @keydown.tab="setSearchToSelected"
      @keyup.enter.exact="setSearchToSelected"
    />
    <ul v-show="showResults" class="autocomplete-results" :class="sizeClass" :style="resultsHeight">
      <li
        v-for="(result, index) in results"
        :key="result[0]"
        class="autocomplete-result"
        :class="{ 'is-active': index === resultIndex }"
        @click="setSearchResult(result)"
        @mouseover="resultIndex = index"
      >
        {{ result[1] }}
      </li>
    </ul>
  </div>
</template>

<script>
// a flexible autocomplete input built on bulma's style classes.
//
// The parent component should manage available results by listening for 'search'
// events and updating the 'results' prop as needed.
//
// In addition to props/emits, this component makes the 'focus' and 'clear' methods
// available for use by parent components, access by adding a ref:
// https://v2.vuejs.org/v2/guide/components-edge-cases.html#Accessing-Child-Component-Instances-amp-Child-Elements

import { debounce as _debounce } from "lodash";

export default {
  props: {
    // results to display in select list. parent should update these
    // dynamically in response to 'search' event.
    //
    // should be an array of [id, displayString] pairs, for example:
    // [[1, "Foo"], [2, "Bar"], ...]
    results: {
      type: Array,
      required: true,
      validator(value) {
        if (!Array.isArray(value)) return false;
        if (!value.length) return true; // empty array
        const first = value[0];
        // elements should be two element arrays of [id, displayString]
        return Array.isArray(first) && first.length === 2;
      },
    },
    // how many seconds to wait after user stops entering input to emit
    // search event. if parent will look things up with a network call,
    // try 200-250.
    debounce: {
      type: Number,
      required: false,
      default: 0,
    },
    // when editing something that is already set, provide the textual
    // representation of the existing value here
    initialValue: {
      type: String,
      required: false,
      default: "",
    },
    // default input placeholder
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    // alter site of input, can be: small, normal, medium, large
    // see examples: https://bulma.io/documentation/form/input/#sizes
    size: {
      type: String,
      required: false,
      default: "normal",
    },
  },
  emits: ["search", "select"],
  data() {
    return {
      resultIndex: -1,
      searchString: "",
      showResults: false,
    };
  },
  computed: {
    activeResult() {
      if (this.resultIndex === -1) return null;
      return this.results[this.resultIndex];
    },
    resultsHeight() {
      const min = "4";
      const max = "16";
      let height;

      height = min;
      if (this.results?.length) {
        height = Math.min(this.results.length * 2, max);
      }
      return { height: height + "rem" };
    },
    sizeClass() {
      return "is-" + this.size;
    },
  },
  watch: {
    initialValue() {
      this.searchString = this.initialValue;
    },
    results: {
      handler(newValue) {
        this.showResults = !!newValue.length;
      },
      deep: true,
    },
  },
  beforeMount() {
    this.searchString = this.initialValue;
  },
  created() {
    if (this.debounce) {
      this.updateSearch = _debounce(this._updateSearch, this.debounce);
    } else {
      this.updateSearch = this._updateSearch;
    }
  },
  methods: {
    // clear the search string / selection, available for use by parent components
    clear() {
      this.searchString = "";
      this.updateSearch();
    },
    closeAutocomplete() {
      this.showResults = false;
      this.resultIndex = -1; // reset
    },
    // focus the input field, available for use by parent components
    focus() {
      this.$refs.searchField.focus();
    },
    moveDown() {
      if (this.showResults && this.resultIndex < this.results.length) {
        this.resultIndex = this.resultIndex + 1;
      }
    },
    moveUp() {
      if (this.showResults && this.resultIndex > 0) {
        this.resultIndex = this.resultIndex - 1;
      }
    },
    setSearchResult(result) {
      this.searchString = result[1]; // name
      this.$emit("select", result);
      this.closeAutocomplete();
    },
    setSearchToSelected() {
      if (this.activeResult) {
        this.setSearchResult(this.activeResult);
      }
    },
    _updateSearch() {
      this.$emit("search", this.searchString.trim());
    },
  },
};
</script>

<style lang="sass" scoped>
.autocomplete
  position: relative
.autocomplete-results
  position: absolute
  width: 100%
  padding: 0
  margin: 0
  border: 1px solid #eee
  height: 12rem
  overflow-x: auto
  overflow-y: hidden
  background: #fff
  z-index: 100
  &.is-small
    font-size: 0.8rem
.autocomplete-result
  padding: 4px 6px
  cursor: pointer
  &.is-active
    background: #d0eafb
input.autocomplete-input:focus
  border-color: #b5b5b5
  box-shadow: inherit
</style>
