import { format } from "@/utils/dateFormatter";
import { currencyFull, currencyShort as _currencyShort } from "@/utils/numberFormatter";
import { capitalize as stringCapitalize } from "@/utils/stringFormatter";

// Filter methods can either be individually imported into components
// and added to their methods for use in the template or used via the
// global $filters property.
//
// Example: {{ $filters.capitalize(firstName) }}

// String formatting filters - see utils/stringFormatter for more

export const capitalize = (value) => {
  if (!value) return "";
  return stringCapitalize(value);
};

export const percentage = (value) => {
  if (!value) return "-";
  return `${parseFloat(Math.round(value * 100) / 100).toFixed(2)}%`;
};

// Currency formatting filters

export const currency = (value) => {
  if (!value) return "-";
  return currencyFull(Number(value));
};

export const currencyRounded = (value) => {
  if (!value) return "-";
  return currencyFull(Math.round(Number(value)));
};

export const currencyShort = (value, blank = "-") => {
  if (!value && value !== 0) return blank;
  return _currencyShort(Number(value));
};

// Date formatting filters - see utils/dateFormatter for more

// take a js date or a YYYY-MMM-DD date string and format it using date-fns conventions:
// https://date-fns.org/v2.7.0/docs/format
export const dateFormat = (dateOrDateString, formatString) => {
  if (!dateOrDateString) return "";
  return format(dateOrDateString, formatString);
};

// ex: Q2 2019
export const quarter = (dateOrDateString) => {
  return dateFormat(dateOrDateString, "qqq yyyy");
};

export default {
  capitalize,
  currency,
  currencyRounded,
  currencyShort,
  dateFormat,
  percentage,
  quarter,
};
