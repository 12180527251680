import { sentry } from "@/common/config";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// https://docs.sentry.io/platforms/javascript/guides/vue/
export const initSentry = (app, router) => {
  if (!sentry.dsn) return;
  Sentry.init({
    app,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "auger.heavybit.com", "auger-stg.heavybit.com", /^\//],
      }),
    ],
    logErrors: true, // publish errors to console as well?
    tracingOptions: {
      trackComponents: true,
    },
    // turn this down if we have real traffic, 0.1 or 0.2?
    // https://docs.sentry.io/platforms/javascript/guides/vue/performance/
    tracesSampleRate: 1.0,
  });
};

// make Sentry available as default or individual export
export { Sentry as default, Sentry };
