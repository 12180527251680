import gql from "graphql-tag";
import fragments from "@/common/fragments";

const addMetric = gql`
  mutation addMetric($objects: [members_metrics_insert_input!]!) {
    insert_members_metrics(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

const createFinancingEvent = gql`
  mutation createFinancingEvent($objects: [financing_events_insert_input!]!) {
    insert_financing_events(objects: $objects) {
      returning {
        ...FinancingEventFields
      }
    }
  }
  ${fragments.financingEventFields}
`;

const createMeasurement = gql`
  mutation createMeasurement($objects: [measurements_insert_input!]!) {
    insert_measurements(
      objects: $objects
      on_conflict: { constraint: measurements_unique_per_period, update_columns: [value] }
    ) {
      returning {
        ...MeasurementFields
      }
    }
  }
  ${fragments.measurementFields}
`;

const saveMeasurementAnnotation = gql`
  mutation saveMeasurementAnnotation($objects: [measurement_annotations_insert_input!]!) {
    insert_measurement_annotations(
      objects: $objects
      on_conflict: {
        constraint: measurement_annotations_measurement_id_unique
        update_columns: [annotation]
      }
    ) {
      returning {
        id
        measurement_id
        annotation
      }
    }
  }
`;

const createMember = gql`
  mutation create_member($objects: [members_insert_input!]!) {
    insert_members(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

const deleteFinancingEvent = gql`
  mutation deleteFinancingEvent($id: Int!) {
    delete_financing_events(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

const deleteMeasurement = gql`
  mutation deleteMeasurement($id: Int!) {
    delete_measurements(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

const updateFinancingEvent = gql`
  mutation update_financing_event($id: Int!, $changes: financing_events_set_input) {
    update_financing_events(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        ...FinancingEventFields
      }
    }
  }
  ${fragments.financingEventFields}
`;

const updateMeasurement = gql`
  mutation update_measurement($id: Int!, $value: numeric!) {
    update_measurements(where: { id: { _eq: $id } }, _set: { value: $value }) {
      affected_rows
      returning {
        ...MeasurementFields
      }
    }
  }
  ${fragments.measurementFields}
`;

const updateMember = gql`
  mutation update_member($id: Int, $changes: members_set_input) {
    update_members(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
    }
  }
`;

const updateUser = gql`
  mutation update_user($id: Int, $changes: users_set_input) {
    update_users(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
    }
  }
`;

export default {
  addMetric,
  createFinancingEvent,
  createMeasurement,
  saveMeasurementAnnotation,
  createMember,
  deleteFinancingEvent,
  deleteMeasurement,
  updateFinancingEvent,
  updateMeasurement,
  updateMember,
  updateUser,
};
