import { dateStringInChunks, toDate } from "@/utils/date";
import { format as _format, formatDistanceToNow } from "date-fns";

// YYYY-MM-DD to MM/DD/YYYY
export const dateStringToMDY = (dateStr) => {
  const date = dateStringInChunks(dateStr);
  return `${date.month}/${date.day}/${date.year}`;
};

// accepts a date string, date object or pipedrive time and formats it with any date-fns
// supported format string: https://date-fns.org/v2.8.0/docs/format
export const format = (dateOrDateString, formatString) => {
  const date = toDate(dateOrDateString); // universal date converter
  return _format(date, formatString);
};

// ex: 10/2018
export const numericMonthYear = (dateOrDateString) => {
  if (!dateOrDateString) return "";
  return format(dateOrDateString, "MM/yyyy");
};

// ex: 2 minutes ago; 3 months ago, etc...
export const timeAgoInWords = (dateOrDateString) => {
  const date = toDate(dateOrDateString);
  return formatDistanceToNow(date, { addSuffix: true });
};

export default {
  dateStringToMDY,
  format,
  numericMonthYear,
  timeAgoInWords,
};
