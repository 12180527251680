import gql from "graphql-tag";
import fragments from "@/common/fragments";

const companyStages = gql`
  query company_stages {
    companyStages: company_stages {
      id
      name
    }
  }
`;

const exitTypes = gql`
  query exit_types {
    exitTypes: exit_types {
      id
      name
    }
  }
`;

const fetchFinancingEvents = gql`
  query fetch_financing_events {
    financingEvents: financing_events(order_by: { closed_on: desc_nulls_first }) {
      ...FinancingEventFields
    }
  }
  ${fragments.financingEventFields}
`;

const fetchMember = gql`
  query fetch_member($id: Int!) {
    member: members_by_pk(id: $id) {
      id
      name
      fundraising
      funnel
      founded_on
      entered_heavybit_on
      carta_id
      pipedrive_id
      company_stage {
        id
        name
      }
      exit_type {
        id
        name
      }
      member_stage {
        id
        name
      }
      go_to_market {
        id
        name
      }
      members_metrics {
        metric {
          ...MetricFields
        }
      }
    }
  }
  ${fragments.metricFields}
`;

const fetchMembersByPipedriveId = gql`
  query fetch_member_by_pipedrive_id($id: Int!) {
    members: members(where: { pipedrive_id: { _eq: $id } }) {
      id
    }
  }
`;

const fetchSharedSecret = gql`
  query fetchSharedSecret($name: String!) {
    shared_secret: shared_secrets(where: { name: { _eq: $name } }) {
      value
      name
    }
  }
`;

const fetchUser = gql`
  query fetchUser($id: Int!) {
    user: users_by_pk(id: $id) {
      id
      email
      name
      fireflies_token
      pipedrive_token
      partner
    }
  }
`;

const financingStages = gql`
  query financing_stages {
    financing_stages {
      id
      name
    }
  }
`;

const financingTypes = gql`
  query financing_types {
    financing_types {
      id
      name
    }
  }
`;

const memberMeasurements = gql`
  query member_measurements($id: Int!) {
    measurements(where: { member_id: { _eq: $id } }, order_by: { metric: asc, ends_on: asc }) {
      ...MeasurementFields
    }
  }
  ${fragments.measurementFields}
`;

const metrics = gql`
  query fetchMetrics {
    metrics {
      id
      name
      full_name
      financial
      units
      units_short
    }
  }
`;

const goToMarkets = gql`
  query go_to_markets {
    goToMarkets: go_to_markets {
      id
      name
      full_name
    }
  }
`;

const membersByStage = gql`
  query members_by_stage {
    stages: member_stages(order_by: { id: asc }) {
      id
      name
      description
      members(order_by: { entered_heavybit_on: desc, name: asc }) {
        id
        name
        pipedrive_id
        fundraising
        entered_heavybit_on
      }
    }
  }
`;

const membersForOverview = gql`
  query membersForOverview($earliestMeasurementDate: date!) {
    members(
      where: { member_stage_id: { _in: [1, 2] }, entered_heavybit_on: { _is_null: false } }
      order_by: { entered_heavybit_on: desc }
    ) {
      id
      name
      entered_heavybit_on
      fundraising
      member_stage {
        id
        name
      }
      measurements(
        where: {
          metric: { _in: ["MRR", "cash", "burn", "conversions", "closed"] }
          ends_on: { _gte: $earliestMeasurementDate }
        }
        order_by: { metric: asc, ends_on: asc }
      ) {
        id
        metric
        value
        starts_on
        ends_on
      }
      go_to_market {
        id
        name
      }
    }
  }
`;

const memberStages = gql`
  query member_stages {
    stages: member_stages {
      id
      name
      description
    }
  }
`;

export default {
  companyStages,
  exitTypes,
  fetchFinancingEvents,
  fetchMember,
  fetchMembersByPipedriveId,
  fetchSharedSecret,
  fetchUser,
  financingStages,
  financingTypes,
  goToMarkets,
  memberMeasurements,
  membersByStage,
  membersForOverview,
  memberStages,
  metrics,
};
