<template>
  <div class="calendar-event columns">
    <div class="column is-narrow">
      <EventDate :date="event.date || event.start.dateTime" />
      <div v-if="recurring" class="recurrence">
        <span class="tag is-rounded">&#8635; repeats</span>
      </div>
    </div>

    <div class="column">
      <EventSummary :event="event" />

      <div class="event-tags is-flex">
        <EventCategory :event="event" @set-category="setEventCategory" />
        <EventResource :event="event" @set-resource="setEventResource" />
        <EventActivity :event="event" :activity="activity" @set-activity="setEventActivity" />
        <div v-if="hasActivity || needsActivity" class="event-details-toggle">
          <button class="button is-text is-small" @click="toggleDetails">
            {{ showDetails ? "Hide" : "Show" }} event details
          </button>
        </div>
      </div>

      <div class="event-details columns" :class="{ collapsed: !showDetails }">
        <div class="column is-three-fifths">
          <div class="tabs is-small">
            <ul>
              <li class="is-active"><a>Description</a></li>
            </ul>
          </div>
          <EventDescription :description="description" />
        </div>

        <div class="column">
          <div class="tabs is-small">
            <ul>
              <li :class="{ 'is-active': selectedTab == 'attendees' }">
                <a @click="selectedTab = 'attendees'"> Attendees ({{ attendeeCount }}) </a>
              </li>

              <li :class="{ 'is-active': selectedTab == 'properties' }">
                <a @click="selectedTab = 'properties'">Properties</a>
              </li>
            </ul>
          </div>

          <EventAttendees
            v-if="selectedTab == 'attendees'"
            :attendees="allAttendees"
            @add-contact="addContact"
          />

          <EventProperties
            v-if="selectedTab == 'properties'"
            :properties="eventProperties"
            @save="updateEvent"
          />
        </div>
      </div>

      <EventActivityEditor
        v-if="hasActivity || needsActivity"
        :event="event"
        :activity="activity"
        :note-written="noteWritten"
        @set-activity="setEventActivity"
      />
    </div>
  </div>
</template>

<script>
import EventActivity from "@/components/EventActivity";
import EventActivityEditor from "@/components/EventActivityEditor";
import EventCategory from "@/components/EventCategory";
import EventDate from "@/components/EventDate";
import EventDescription from "@/components/EventDescription";
import EventAttendees from "@/components/EventAttendees";
import EventProperties from "@/components/EventProperties";
import EventResource from "@/components/EventResource";
import EventSummary from "@/components/EventSummary";
import googleCal from "@/common/google/calendar";
import { googleClient } from "@/common/google/client";

export default {
  components: {
    EventActivity,
    EventActivityEditor,
    EventCategory,
    EventDate,
    EventDescription,
    EventAttendees,
    EventProperties,
    EventResource,
    EventSummary,
  },
  props: {
    calendar: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
      default: null,
    },
  },
  emits: ["event-updated", "add-contact"],
  data() {
    return {
      showDetails: true,
      noteWritten: false,
      tabs: ["attendees", "properties", "recurrence"],
      selectedTab: "attendees",
    };
  },
  computed: {
    hasActivity() {
      return !!this.event?.extendedProperties?.private?.activityId;
    },
    needsActivity() {
      const categories = ["portfolio", "pipeline", "networking"];
      const properties = this.event?.extendedProperties?.private || {};
      const hasCategory = categories.includes(properties.eventCategory);
      const resourceId = properties.dealId || properties.organizationId || properties.personId;
      return hasCategory && resourceId != null && !this.hasActivity;
    },
    recurring() {
      return this.event.recurrence || this.event.recurringEventId;
    },
    allAttendees() {
      return (this.event.attendees || []).filter((attendee) => !attendee.resource);
    },
    acceptedAttendees() {
      return this.allAttendees.filter((attendee) => attendee.responseStatus == "accepted");
    },
    attendeeCount() {
      return this.allAttendees.length == this.acceptedAttendees.length
        ? this.allAttendees.length
        : `${this.acceptedAttendees.length}/${this.allAttendees.length}`;
    },
    description() {
      return this.event?.description || "";
    },
    eventProperties() {
      return this.event?.extendedProperties || {};
    },
  },
  watch: {
    hasActivity: {
      immediate: true,
      handler(hasActivity) {
        this.showDetails = !hasActivity;
      },
    },
  },
  mounted() {
    if (this.hasActivity) {
      this.noteWritten = this.event.extendedProperties?.private?.note === "true";
    }
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    async fetchEvent(calendarId, eventId) {
      const client = await googleClient();
      if (!client) return; // google not available

      return googleCal.getEvent(client, calendarId, eventId).then((resp) => resp.result);
    },
    setEventCategory: async function (category) {
      const extendedProperties = {
        private: {
          isSet: true,
          eventCategory: category,
          activityId: "",
        },
      };
      this._updateExtendedProperties(this.event.id, extendedProperties).then((updatedEvent) => {
        this.$emit("event-updated", this.event.id, updatedEvent);
      });
      /* TODO: this is for recurring events, but it's not working
      const eventId = this.recurring ? this.event.recurringEventId : this.event.id;
      let updatedEvent = await this._updateExtendedProperties(eventId, extendedProperties);
      if (this.recurring) {
        // event returned from update is recurring parent event, so fetch current
        // state of this event
        updatedEvent = await this.fetchEvent(this.calendar, this.event.id);
        // parent/child updates are eventually consistent, timing may miss, ensure
        // extended props matches known state
        updatedEvent.extendedProperties = extendedProperties;
      } */
    },
    setEventResource: async function (resourceProperties) {
      const extendedProperties = {
        private: resourceProperties,
      };
      this._updateExtendedProperties(this.event.id, extendedProperties).then((updatedEvent) => {
        this.$emit("event-updated", this.event.id, updatedEvent);
      });
    },
    setEventActivity(activityProperties) {
      if (Object.keys(activityProperties).includes("note")) {
        this.noteWritten = activityProperties.note;
      }
      const extendedProperties = {
        private: activityProperties,
      };
      this._updateExtendedProperties(this.event.id, extendedProperties).then((updatedEvent) => {
        this.$emit("event-updated", this.event.id, updatedEvent);
      });
    },
    addContact(person) {
      this.$emit("add-contact", person);
    },
    updateEvent(properties) {
      this._updateExtendedProperties(this.event.id, properties).then((updatedEvent) => {
        this.$emit("event-updated", this.event.id, updatedEvent);
      });
    },
    async _updateExtendedProperties(eventId, extendedProperties) {
      const client = await googleClient();
      if (!client) return; // google not available

      return googleCal
        .updateEvent(client, this.calendar, eventId, { extendedProperties })
        .then((response) => response.result)
        .catch((response) => {
          console.error(JSON.parse(response.body).error);
          let errors = JSON.parse(response.body).error.errors;
          errors.map((e) => this.$toasted.error(e.message));
          throw errors;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.calendar-event
  &:not(:last-child)
    border-bottom: 1px solid #e5e5e5
  .recurrence
    text-align: center
  .event-tags
    margin-top: 0.5rem
    margin-bottom: 0.5rem
    min-height: 1.5rem
    > div
      margin-right: 0.5rem
    .event-details-toggle
      margin-right: 0
      margin-left: auto
      button
        text-decoration: none
    .tags .tag > :not(:last-child)
        margin-right: 0.3rem
  .event-details
    opacity: 1
    max-height: 50rem
    transition: 0.2s 0s ease-out
    &.collapsed
      transition: 0.2s 0s ease-out
      max-height: 0
      opacity: 0
      overflow: hidden
  .tabs.is-small
    margin-top: 0.5rem
    margin-bottom: 0.8rem
    li a
      padding: 0.1rem 0.4rem 0.1rem 0.4rem
    + div
      min-height: 12rem
</style>
