<template>
  <div v-if="hasDescription" class="event-description is-size-7" v-html="cleanDescription"></div>
  <div v-else class="event-description is-size-7">
    <p class="is-disabled is-italic">No description available.</p>
  </div>
</template>

<script>
import { sanitizeXSS, stripTags } from "@/utils/html";

export default {
  components: {},
  props: {
    description: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasDescription() {
      return this.description?.length > 0;
    },
    cleanDescription() {
      if (!this.hasDescription) return "";
      // remove unnecessary Zoom formatting & blanklines
      if (this.description?.includes("Zoom meeting.")) {
        var cleanDescription = this.description.replace(/<br>/g, "\n");
        cleanDescription = cleanDescription.replace(/(─)\1{9,}/g, "");
        cleanDescription = stripTags(cleanDescription);
        cleanDescription = cleanDescription.split("\n---")[0] + "..."; // Get rid of all the content after the line that is just '---'
        return sanitizeXSS(cleanDescription.trim());
      }

      return sanitizeXSS(this.description).trim();
    },
  },
};
</script>

<style lang="sass" scoped>
.event-description
  white-space: pre-line
  overflow: scroll
  color: #7a7a7a
  max-height: 14rem
  word-break: break-word
  margin-bottom: 0.8rem
</style>
