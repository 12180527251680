// common methods for accessing google drive APIs

// Gets a file's metadata or content by ID
// https://developers.google.com/drive/api/v3/reference/files/get
export const getFile = function (client, fileId, fields) {
  const params = {
    fileId: fileId,
    supportsAllDrives: true,
    fields,
  };
  return client.drive.files.get(params);
};

export const listFiles = function (client, folderId) {
  const params = {
    corpora: "drive",
    driveId: "0AEpOzi-5DfbpUk9PVA",
    // Using the "*" fields parameter is only recommended for development by Google
    // The fields param should be set to a comma delimited list of required fields
    // however there seems to be a bug in the API where a comma delimited field list
    // is escaped in the URL and Google says it's invalid.
    // fields: "id,kind,mimeType,webViewLink,iconLink",
    fields: "*",
    includeItemsFromAllDrives: true,
    q: `'${folderId}' in parents`,
    supportsAllDrives: true,
  };

  return client.drive.files.list(params);
};

export const renderFile = function (client, fileId) {
  const params = { fileId, mimeType: "text/html" };
  return client.drive.files.export(params);
};

// utility sort method: sort files A-Z, folders before individual files
export const sortFiles = function (a, b) {
  if (!a.mimeType.includes("folder") && b.mimeType.includes("folder")) return 1;
  if (a.mimeType.includes("folder") && !b.mimeType.includes("folder")) return -1;
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  if (aName > bName) return 1;
  if (aName < bName) return -1;
  return 0;
};

export default {
  getFile,
  listFiles,
  renderFile,
  sortFiles,
};
