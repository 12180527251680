import api from "./api";
import filters from "./filters";

export const activities = {
  // create a new activity
  // https://developers.pipedrive.com/docs/api/v1/#!/Activities/post_activities
  // required fields: subject, type
  async create(options = {}) {
    const response = await api.post("activities", options);
    return response.data?.data;
  },
  // find activities with specific criteria
  // https://developers.pipedrive.com/docs/api/v1/#!/Activities/get_activities
  // available filters: start_date, end_date, user_id, type, filter_id
  async filtered(options = {}) {
    // search all users' activities by default
    const params = Object.assign({}, { user_id: 0 }, options);
    const response = await api.get("activities", params);
    return response.data?.data || [];
  },
  // fetch activities associated with a particular organization
  // https://developers.pipedrive.com/docs/api/v1/Organizations#getOrganizationActivities
  // useful params: done, exclude (ids not to return), limit
  async forOrganization(orgId, params = {}) {
    const response = await api.get(`organizations/${orgId}/activities`, params);
    return response.data?.data || [];
  },
  // update properties of a specific activity
  // https://developers.pipedrive.com/docs/api/v1/Activities#updateActivity
  async update(id, params = {}) {
    const response = await api.put(`activities/${id}`, params);
    return response.data?.data;
  },
};

export const deals = {
  // fetch deals by a specific filter
  async byFilter(filterId, options = {}) {
    return _fetchDealsByFilter(filterId, options);
  },
  // fetch a set of deals by name or partial name
  // https://developers.pipedrive.com/docs/api/v1/#!/Deals/get_deals_search
  async byName(search, options = {}) {
    const params = Object.assign({}, { term: search, limit: 20 }, options);

    try {
      const response = await api.get("deals/search", params);
      return _extractResultsFromSearch(response);
    } catch (err) {
      console.error("deals.byName", err);
      throw err;
    }
  },
  // fetch by stage
  async byStage(stageId, options = {}) {
    const params = Object.assign({ limit: 1000 }, options, { stage_id: stageId });
    const response = await api.get("deals", params);
    return response.data?.data || [];
  },
  // fetch deals in a specific pipeline
  async inPipeline(pipelineId, options = {}) {
    try {
      const response = await api.get(`pipelines/${pipelineId}/deals`, options);
      return response.data?.data;
    } catch (err) {
      console.error("deals.inPipeline", err);
      throw err;
    }
  },
  // fetch deals associated with a specific person
  // https://developers.pipedrive.com/docs/api/v1/Persons#getPersonDeals
  async forPerson(personId, options = {}) {
    try {
      const response = await api.get(`persons/${personId}/deals`, options);
      return response.data?.data;
    } catch (err) {
      console.error("deals.forPerson", err);
      throw err;
    }
  },
};

export const dealFields = {
  // get all details for a single deal field
  // https://developers.pipedrive.com/docs/api/v1/DealFields#getDealField
  async details(fieldId) {
    try {
      let response = await api.get(`dealFields/${fieldId}`);
      return response.data?.data;
    } catch (err) {
      console.error("dealFields.details", err);
      throw err;
    }
  },
};

export const notes = {
  // add a new note
  // https://developers.pipedrive.com/docs/api/v1/Notes#getComment
  // key params: content, lead_id, person_id, deal_id, org_id
  async create(params = {}) {
    const response = await api.post("notes", params);
    return response?.data?.data;
  },
  // fetch notes related to a specific organization
  async forOrganization(orgId, options = {}) {
    const params = Object.assign({}, { org_id: orgId, sort: "add_time DESC" }, options);
    const response = await api.get("notes", params);
    return response?.data?.data || [];
  },
  // update a note
  // https://developers.pipedrive.com/docs/api/v1/Notes#updateNote
  // 'content' is a required param when updating a note
  async update(id, params = {}) {
    const response = await api.put("notes/" + id, params);
    return response?.data?.data;
  },
};

export const organizations = {
  // fetch details of a single organization by ID
  // https://developers.pipedrive.com/docs/api/v1/#!/Organizations/getOrganization
  async byId(id) {
    try {
      let response = await api.get("/organizations/" + id);
      return response.data?.data;
    } catch (err) {
      console.error("organizations.byId", err);
      throw err;
    }
  },
  // fetch a set of organizations as a group by id
  // callback allows faster access to eventual result set
  async byIds(ids, callback = false) {
    const filter = await filters.createIdFilter("org", ids);
    if (!filter) return []; // err already thrown
    const orgs = await _fetchOrgsByFilter(filter);
    if (callback) callback(orgs); // allow delivery of data before cleaning up filter
    await filters.destroy(filter);
    return orgs;
  },
  // fetch organizations by a specific filter
  async byFilter(filterId, options = {}) {
    return _fetchOrgsByFilter(filterId, options);
  },
  // fetch a set of organizations by name or partial name
  // https://developers.pipedrive.com/docs/api/v1/#!/Organizations/searchOrganization
  async byName(search, options = {}) {
    const params = Object.assign({}, { fields: "name", term: search, limit: 20 }, options);

    try {
      let response = await api.get("organizations/search", params);
      return _extractResultsFromSearch(response);
    } catch (err) {
      console.error("organizations.byName", err);
      throw err;
    }
  },
};

export const organizationFields = {
  // get all details for a single organization field
  // https://developers.pipedrive.com/docs/api/v1/OrganizationFields#getOrganizationField
  async details(fieldId) {
    try {
      let response = await api.get(`organizationFields/${fieldId}`);
      return response.data?.data;
    } catch (err) {
      console.error("organizationFields.details", err);
      throw err;
    }
  },
};

export const people = {
  // create a new person
  // https://developers.pipedrive.com/docs/api/v1/Persons#addPerson
  async create(params = {}) {
    const response = await api.post("persons", params);
    return response.data?.data;
  },

  // fetch details of a single person by id
  async byId(id) {
    try {
      let response = await api.get("persons/" + id);
      return response.data?.data;
    } catch (err) {
      console.error("people.byId", err);
      throw err;
    }
  },
  // fetch a set of people as a group by id
  // callback allows faster access to eventual result set
  async byIds(ids, callback = false) {
    const filter = await filters.createIdFilter("people", ids);
    if (!filter) return []; // err already thrown
    const people = await _fetchPeopleByFilter(filter);
    if (callback) callback(people); // allow delivery of data before cleaning up filter
    await filters.destroy(filter);
    return people;
  },
  // fetch a set of people based on a set of emails. Note that more than
  // one person may be returned for the same email as some people exist in pipedrive
  // more than once
  async byEmails(emails, callback = false) {
    const filter = await filters.createEmailFilter(emails);
    if (!filter) return []; // err already thrown
    const people = await _fetchPeopleByFilter(filter);
    if (callback) callback(people); // allow delivery of data before cleaning up filter
    await filters.destroy(filter);
    return people;
  },
  // fetch people who match a single email
  // https://developers.pipedrive.com/docs/api/v1/#!/Persons/searchPersons
  async byEmail(email, options = {}) {
    const params = Object.assign({}, { term: email, fields: "email", limit: 20 }, options);

    try {
      let response = await api.get("persons/search", params);
      return _extractResultsFromSearch(response);
    } catch (err) {
      console.error("people.byEmail", err);
      throw err;
    }
  },
  // fetch a set of people by name or partial name
  // https://developers.pipedrive.com/docs/api/v1/#!/Persons/searchPersons
  async byName(search, options = {}) {
    const params = Object.assign({}, { term: search, fields: "name", limit: 20 }, options);

    try {
      let response = await api.get("persons/search", params);
      return _extractResultsFromSearch(response);
    } catch (err) {
      console.error("people.byName", err);
      throw err;
    }
  },
  // update details of a person
  // https://developers.pipedrive.com/docs/api/v1/Persons#updatePerson
  async update(id, params = {}) {
    const response = await api.put("persons/" + id, params);
    return response?.data?.data;
  },
};

export const stages = {
  async byPipeline(pipelineId, options = {}) {
    const params = Object.assign({ limit: 1000 }, options, { pipeline_id: pipelineId });
    const response = await api.get("stages", params);
    return response.data?.data || [];
  },
};

const _extractResultsFromSearch = function (response) {
  const items = response?.data?.data?.items || [];
  if (items && items.length) {
    // pipedrive searches return an items property which is an array of objects
    // with two properties, 'result_score' and 'item' (the actual entity we care about).
    // returns an array of entities with result_score added as a property to each.
    return items.map((item) => ({ ...item.item, result_score: item.result_score }));
  } else {
    return [];
  }
};

const _fetchDealsByFilter = (filter, options = {}) => {
  const params = Object.assign({}, { filter_id: filter }, options);
  return api
    .get("deals", params)
    .then((result) => result.data.data)
    .catch((err) => {
      console.error("fetching deals by filter failed", err);
      throw err;
    });
};

const _fetchOrgsByFilter = (filter, options = {}) => {
  const params = Object.assign({}, { filter_id: filter }, options);
  return api
    .get("organizations", params)
    .then((result) => result.data.data)
    .catch((err) => {
      console.error("fetching orgs by filter failed", err);
      throw err;
    });
};

const _fetchPeopleByFilter = (filter) => {
  // console.log("fetchPeopleWithFilter", filter);
  return api
    .get("persons", { filter_id: filter })
    .then((result) => result.data.data)
    .catch((err) => {
      console.error("fetching people by filter failed", err);
      throw err;
    });
};

export default {
  activities,
  deals,
  dealFields,
  organizations,
  organizationFields,
  people,
};
