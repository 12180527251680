// mimetype example: "text/csv;charset=utf-8"
export const downloadFile = function (filename, mimetype, data) {
  // Create an anchor element and inline data
  const anchor = document.createElement("a");
  anchor.href = "data:" + mimetype + "," + encodeURIComponent(data);
  anchor.target = "_blank";
  anchor.download = filename;

  // Trigger the download
  anchor.click();
};
