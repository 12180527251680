<template>
  <div class="event-category">
    <div v-if="!eventCategory" class="tags">
      <span
        v-for="category in categories"
        :key="category"
        class="tag"
        :class="category"
        @click="setEventCategory(category)"
      >
        {{ category }}
      </span>
    </div>
    <div v-else class="tags">
      <span class="tag selected" :class="eventCategory">
        <span>{{ eventCategory }} </span>
        <button class="delete is-small" @click="setEventCategory(null)"></button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  emits: ["set-category"],
  data() {
    return {
      categories: ["portfolio", "pipeline", "networking", "management", "personal", "other"],
    };
  },
  computed: {
    eventCategory() {
      if (!this.event.extendedProperties || !this.event.extendedProperties.private) return null;
      return this.event.extendedProperties.private.eventCategory;
    },
  },
  methods: {
    setEventCategory(category) {
      this.$emit("set-category", category);
    },
  },
};
</script>

<style lang="sass" scoped>
.event-category .tags
  padding-top: 1px
  .tag
    cursor: pointer
  .tag.selected
    font-weight: 600
  .personal:hover,
  .personal.selected
    color: white
    background: slateblue
  .portfolio:hover,
  .portfolio.selected
    color: white
    background: mediumseagreen
  .pipeline:hover,
  .pipeline.selected
    color: white
    background: orange
  .networking:hover,
  .networking.selected
    color: white
    background: lightskyblue
  .management:hover,
  .management.selected
    color: white
    background: cadetblue
  .other:hover,
  .other.selected
    color: white
    background: darkturquoise
</style>
