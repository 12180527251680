<template>
  <form class="metric-annotation-form">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <p class="subtitle is-5">Annotate Metric</p>
        </div>
        <div class="level-item">
          <input v-model="annotation" type="text" class="input" />
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <p>
            <button class="button is-success" @click.prevent="saveAnnotation">Save</button>
          </p>
        </div>
        <div class="level-item">
          <p>
            <button class="button" @click.prevent="hideForm">Cancel</button>
          </p>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import fragments from "@/common/fragments";
import mutations from "@/common/mutations";

export default {
  props: {
    measurement: {
      type: Object,
      default: () => {},
    },
    memberId: {
      type: Number,
      default: null,
    },
  },
  emits: ["hide-form"],
  data() {
    return {
      annotation: "",
    };
  },
  computed: {},
  watch: {
    measurement(newVal) {
      if (newVal && newVal.measurement_annotation) {
        this.annotation = newVal.measurement_annotation.annotation;
      } else {
        this.annotation = null;
      }
    },
  },
  methods: {
    hideForm() {
      this.$emit("hide-form");
    },
    saveAnnotation() {
      this.$apollo.mutate({
        // We use an "upsert" mutation to create or update when saving
        mutation: mutations.saveMeasurementAnnotation,
        variables: {
          objects: [{ measurement_id: this.measurement.id, annotation: this.annotation }],
        },
        update: (cache, { data: { insert_measurement_annotations } }) => {
          const identifier = cache.identify({
            __typename: "measurements",
            id: this.measurement.id,
          });
          const measurement = cache.readFragment({
            id: identifier,
            fragment: fragments.measurementFields,
          });
          // if the measurement already has an annotation and it is being updated,
          // apollo can update its cache automatically based on the identifier.
          // if the annotation is brand new for the measurement we have to write
          // it in manually.
          if (measurement && measurement.measurement_annotation == null) {
            const annotation = insert_measurement_annotations.returning[0];
            cache.writeFragment({
              id: identifier,
              fragment: fragments.measurementFields,
              data: { ...measurement, measurement_annotation: annotation },
            });
          }
          // all done
          this.hideForm();
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.metric-annotation-form
  input
    max-width: 800px
    width: 500px
</style>
