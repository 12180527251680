export const isPositive = (num) => {
  return num !== null && num > 0.0;
};

export const isNegative = (num) => {
  return num !== null && num < 0.0;
};

// value is either a number or a valid number string
export const isNumeric = (num) => {
  return !isNaN(num - parseFloat(num));
};

export const sum = (numbers) => {
  const _sum = (amount, element) => amount + element;
  return numbers.reduce(_sum, 0);
};

// given an array of objects <elements>, sum all the values for a given property <key>
// example: sumProperty([{a: 1, b: 2}, ...], "b")
export const sumProperty = (elements, key) => {
  return sum(elements.map((e) => Number(e[key])));
};

// turn numeric values (numbers or number strings) into numbers
// return null for any other value
export const toNumberOrNull = (value) => {
  return isNumeric(value) ? value * 1 : null;
};

// convert string to a number, stripping out currency symbols & commas
export const toRawNumber = (value) => {
  if (value !== 0 && !value) return value; // falsy non-zero values
  if (!/\d/.test(value.toString())) return value; // no number in the string
  return Number(value.toString().replace(/[,$]/g, ""));
};

export default {
  isPositive,
  isNegative,
  isNumeric,
  toNumberOrNull,
  toRawNumber,
};
