<template>
  <li
    v-click-outside="deselect"
    class="program-activity"
    :class="{ selected: selected }"
    @click="select"
  >
    <!-- Activity Title -->
    <p class="has-text-weight-bold" :class="statusClass">{{ subject }}</p>
    <!-- Time & type -->
    <div class="is-size-7">
      {{ startTime }}
      <!-- this element needs to stay in the DOM for select/deselect behavior to work properly, don't use v-if -->
      <span v-show="!selected" style="padding-left: 0.25rem">
        {{ activityName }}
      </span>
      <select v-if="selected" v-model="activityType" class="inline" @change="update">
        <option v-for="type in activityTypes" :key="type.id" :value="type.key_string">
          {{ type.name }}
        </option>
      </select>
    </div>
    <!-- Show avatars if selected -->
    <div v-if="selected" class="participants is-flex is-flex-direction-row">
      <a
        v-for="person in activity.participants"
        :key="person.id"
        :href="personUrl(person.id)"
        target="_blank"
        @click.stop
      >
        <span class="has-tooltip-right has-tooltip-arrow" :data-tooltip="person.name">
          <img
            class="avatar small"
            :class="{ selected: selectedPersonId == person.id }"
            :src="person.icon_url"
            :alt="person.name"
          />
        </span>
      </a>
    </div>
  </li>
</template>

<script>
import { capitalize, dateFormat } from "@/common/filters";
import { personUrl } from "@/common/pipedrive/web";

export default {
  props: {
    activity: {
      type: Object,
      required: true,
    },
    activityTypes: {
      type: Array,
      required: true,
    },
    selectedPersonId: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },
  emits: ["deselect", "select", "update"],
  data() {
    return {
      selected: false,
      activityType: null,
    };
  },
  computed: {
    activityName() {
      const type = this.activityTypes.find((t) => t.key_string == this.activity.type);
      const name = type ? type.name : this.activity.type.replace(/_/g, " ");
      return capitalize(name);
    },
    startTime() {
      return dateFormat(this.activity.start, "h:mmaaa");
    },
    statusClass() {
      if (["additional_notes", "introduction"].includes(this.activity)) return "complete";
      if (this.activity.done && this.activity.content?.length) return "complete";
      return "needs-action";
    },
    subject() {
      return this.activity?.subject;
    },
  },
  watch: {
    activity(newActivity) {
      this.activityType = newActivity.type;
    },
  },
  beforeMount() {
    this.activityType = this.activity?.type;
  },
  methods: {
    deselect() {
      if (!this.selected) return;
      this.$emit("deselect", this.activity);
      this.selected = false;
    },
    personUrl,
    select() {
      if (this.selected) return;
      this.selected = true;
      this.$emit("select", this.activity);
    },
    update() {
      this.$emit("update", this.activity);
    },
  },
};
</script>

<style lang="sass" scoped>
.program-activity
  cursor: pointer
  min-height: 4rem
  max-height: 4.5rem
  transition: max-height 0.5s ease-out
  padding: 0.3rem 0.5rem
  padding-bottom: 0.5rem
  margin-bottom: 0.5rem
  border-radius: 0.3rem
  border: 1px solid white
  &.selected
    max-height: 1000px
    transition: max-height 0.5s ease-in
    background: #f9f9f9
    select
      border-bottom-color: #ccc
  .participants
    margin-top: 0.8rem
    overflow: hidden
    flex-wrap: wrap
    gap: 0 0.25rem
    span
      border-bottom: none
    img
      margin-right: 0.2rem
  .details
    margin-top: -1rem
    display: inline
  select
    font: inherit
    font-size: 0.75rem
    // margin-top: 0.5rem
    // margin-bottom: 0.5rem
    margin:0
    padding: 0
    width: 8rem
    border: 0
    border-bottom: 1px solid white
    background: none
    &.inline
      margin: 0
      display: inline-block
    &:focus
      outline: none
      border-bottom-color: #3273dc

em
  color: #999

span.has-tooltip-arrow
  display: inline-block

.complete:before,
.needs-action:before,
.incomplete:before
  float: right
  display: inline
  margin-left: 0.5rem
  font-weight: 700
.complete:before
  content: '✓'
  color: ForestGreen
.incomplete:before
  content: '✗'
  color: red
.needs-action:before
  content: '?'
  color: #ffc300
  font-size: 15px
  margin-top: -2px
</style>
