// depending on which representation is fetched, email can be a string
// or an object with a set of emails. this will return the right string
// regardless
export const personEmail = function (person) {
  return typeof person.email === "object"
    ? person.email.filter((e) => e.primary)[0].value
    : person.email;
};

// url for the person's custom picture or a default image
export const personPictureUrl = function (person) {
  if (person?.picture_id) return person.picture_id.pictures["128"];
  if (person?.pictures && person.pictures.length) return person.pictures[0].url;
  return "/img/avatar_120x120.png";
};

export default {
  personEmail,
};
