<template>
  <div class="program-notes">
    <h3 class="is-size-5 mb-4">Notes</h3>

    <!-- No notes -->
    <div v-if="notes.length == 0" class="has-text-centered">
      <p class="is-size-6 is-disabled">No notes found</p>
    </div>

    <!-- Pipedrive formatted notes -->
    <div>
      <ProgramOverviewNote v-for="note in filteredNotes" :key="note.id" :note="note" />
    </div>
  </div>
</template>

<script>
import ProgramOverviewNote from "@/components/ProgramOverviewNote.vue";

export default {
  components: { ProgramOverviewNote },
  props: {
    notes: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedActivity: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showEmptyNotes: true,
      editedNoteId: null,
    };
  },
  computed: {
    // Return the notes that match the filter text
    filteredNotes() {
      let notes = this.notes;
      // if a cal activity is selected, filter to only that activity's note and other
      // "additional notes" related to the organization.
      if (this.selectedActivity) {
        notes = notes.filter((note) => {
          if (note.id === this.selectedActivity.id) return true;
          return note.org_id == this.selectedActivity.org_id && note.type === "additional_notes";
        });
      }
      return this.showEmptyNotes ? notes : notes.filter((n) => n.content?.length > 12);
    },
  },
  methods: {
    // isExpanded(note) {
    //   return note.id == (this.selectedNoteId || note.id);
    // },
    isEditing(note) {
      return note.id == this.editedNoteId;
    },
  },
};
</script>

<style lang="sass" scoped>
.program-notes
  // div.note
  //   opacity: 0.2
  //   max-height: 3.5rem
  //   transition: max-height 5s ease-out
  //   overflow-y: scroll
  //   &.is-expanded
  //     opacity: 1
  //     max-height: 50rem
  //     transition: max-height 0.5s ease-in
</style>
