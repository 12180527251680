<template>
  <div class="program-partners is-flex">
    <div v-for="p in partners" :key="p.id" class="partner" @click="selectPartner(p)">
      <img
        class="avatar"
        :src="p.icon_url || '/img/avatar_120x120.png'"
        :class="{ small: small, selected: selectedId == p.id }"
        :alt="p.name"
        :title="p.name"
      />
    </div>
  </div>
</template>

<script>
import pipedrive from "@/common/pipedrive";
import { PARTNERS as partnerContacts } from "@/common/partners";

export default {
  props: {
    ids: {
      type: Array,
      required: false,
      default: () => [],
    },
    emails: {
      type: Array,
      required: false,
      default: () => [],
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    team: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["select"],
  data() {
    return {
      users: [],
      selectedId: null,
      defaultEmails: [
        "tom@heavybit.com",
        "joe@heavybit.com",
        "jesse@heavybit.com",
        "james@heavybit.com",
        "dana@heavybit.com",
      ],
    };
  },
  computed: {
    partnerFilter() {
      // The component can be provided an array of ids or emails to display
      let ids = this.ids || [];
      if (ids.length > 0)
        return function (p) {
          return ids.includes(p.id);
        };

      let emails = this.emails?.length > 0 ? this.emails : this.defaultEmails;
      return function (p) {
        return emails.includes(p.email);
      };
    },
    partners() {
      if (!this.users || this.users?.length == 0) return [];
      var partners = this.users.filter(this.partnerFilter);
      if (this.team) {
        let team = this.users.filter((u) => u.email === "internal@heavybit.com");
        partners = [...team, ...partners];
      }
      return partners.map((p) => {
        return {
          ...p,
          user_id: p.id,
          id: partnerContacts[p.id] || 0,
        };
      });
    },
  },
  mounted() {
    // Only load partners remotely if list is not provided
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      let params = {};
      pipedrive.api
        .get("users", params)
        .then((response) => (this.users = response.data.data || []));
    },
    selectPartner(person) {
      let deselectPerson = this.selectedId == person.id;
      var newId = deselectPerson ? null : person.id;
      var newPerson = deselectPerson ? null : person;

      this.selectedId = newId;
      this.$emit("select", newPerson);
    },
  },
};
</script>

<style lang="sass">
.program-partners div img
  margin-left: 0.5rem
  cursor: pointer
.program-partners div img.small
  margin-left: 0.3rem
</style>
