import union from "lodash/union";

// metrics to add for a specific go to market
const defaultForGTM = (goToMarket) => {
  const universal = ["MRR", "burn", "cash"]; // metrics for all members
  const byGTM = {
    free_trial: [],
    freemium: [],
    enterprise: ["bookings", "MQLs", "SQLs", "evaluations", "contracts", "closed"],
    oss_opencore: [],
    oss_cloud: [],
  };

  const fromGTM = byGTM[goToMarket];
  return fromGTM ? union(universal, fromGTM) : universal;
};

// sort order
const financialOrder = ["bookings", "MRR", "burn", "cash"];
const operationalOrder = [
  "MQLs",
  "SQLs",
  "evaluations",
  "contracts",
  "closed",
  "uniques",
  "signups",
  "trials",
  "activations",
  "conversions",
];

// order an array of metrics according to an established rank order
const order = (metrics, sortOrder) => {
  return metrics.sort((a, b) => {
    if (sortOrder.indexOf(a.name) === -1 && sortOrder.indexOf(b.name) !== -1) {
      return 1;
    } else if (sortOrder.indexOf(a.name) !== -1 && sortOrder.indexOf(b.name) === -1) {
      return -1;
    } else if (sortOrder.indexOf(a.name) < sortOrder.indexOf(b.name)) {
      return -1;
    } else if (sortOrder.indexOf(a.name) > sortOrder.indexOf(b.name)) {
      return 1;
    } else {
      return 0;
    }
  });
};

// take an arrray of metrics and split it into sorted sets
export const segmentAndOrder = (metrics) => {
  const financial = order(
    metrics.filter((m) => m.financial),
    financialOrder
  );
  const operational = order(
    metrics.filter((m) => !m.financial),
    operationalOrder
  );

  return { financial, operational };
};

export default {
  defaultForGTM,
  segmentAndOrder,
};
