<template>
  <tr class="financing-event-row" :class="{ estimate: event.estimate }">
    <td>{{ numericMonthYear(event.closed_on) }}</td>
    <td>
      <router-link
        v-if="event.company_pipedrive_id && event.company_name"
        :to="{ name: 'company-show', params: { id: event.company_pipedrive_id } }"
        class="subtle-link"
      >
        {{ event.company_name }}
      </router-link>
    </td>
    <td>
      <span class="has-tooltip-arrow" :data-tooltip="type">{{ stage }}</span>
    </td>
    <td>
      <span class="has-tooltip-arrow" :data-tooltip="partnerNameOrId">
        <a :href="leadLink" target="_blank" class="subtle-link">{{ leadNameOrId }}</a>
      </span>
    </td>
    <td class="has-text-right">
      {{ currencyShort(event.ARR) }}
    </td>
    <td class="has-text-right">
      <span class="has-tooltip-arrow" :data-tooltip="percentagePurchased">
        {{ currencyShort(event.investment) }}
      </span>
    </td>
    <td class="has-text-right">
      <span class="has-tooltip-arrow" :data-tooltip="'Pre-Money: ' + preMoney">
        {{ currencyShort(event.post_money) }}
      </span>
    </td>
    <td class="notes is-relative">
      <span v-show="event.notes" class="has-tooltip-arrow" :data-tooltip="trimmedNotes">
        &hellip;
      </span>
      <a class="edit" @click="startEdit">
        <font-awesome-icon icon="pen" class="edit-icon" size="sm" />
      </a>
    </td>
  </tr>
</template>

<script>
import { currencyShort } from "@/common/filters";
import { web as pipedriveWeb } from "@/common/pipedrive";
import { numericMonthYear } from "@/utils/dateFormatter";

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    leadName: {
      type: String,
      default: null,
    },
    partnerName: {
      type: String,
      default: null,
    },
  },
  emits: ["edit"],
  computed: {
    leadLink() {
      return pipedriveWeb.organizationUrl(this.event.lead_investor_pipedrive_id);
    },
    leadNameOrId() {
      return this.leadName || this.event.lead_investor_pipedrive_id;
    },
    partnerNameOrId() {
      return this.partnerName || this.event.partner_pipedrive_id;
    },
    percentage() {
      if (!this.event.investment || !this.event.post_money) return null;
      return ((this.event.investment / this.event.post_money) * 100).toFixed(2);
    },
    percentagePurchased() {
      if (!this.percentage) return "Unknown %";
      return this.percentage + "% purchased";
    },
    preMoney() {
      if (!this.event.investment || !this.event.post_money) return "";
      return this.currencyShort(this.event.post_money - this.event.investment);
    },
    stage() {
      return this.event.financing_stage && this.event.financing_stage.name;
    },
    trimmedNotes() {
      const notes = this.event.notes || "";
      return notes.length >= 256 ? notes.substring(0, 256) + "…" : notes;
    },
    type() {
      return this.event.financing_type && this.event.financing_type.name;
    },
  },
  methods: {
    currencyShort,
    numericMonthYear,
    startEdit() {
      this.$emit("edit");
    },
  },
};
</script>

<style lang="sass" scoped>
@use 'sass:color'

$lightened-row: color.adjust( #363636, $lightness: 30% )
.edit
  color: color.adjust(#363636, $lightness: 20%)
  padding: 5px
  position: absolute
  // right: -2rem
  opacity: 0.0
  top: 50%
  transform: translateY(-50%)
.estimate
  color: $lightened-row
  .subtle-link
    color: $lightened-row
    &:hover
      color: color.adjust( $lightened-row, $lightness: -20% )
.financing-event-row:hover .edit
  transition: 0.1s ease-in
  opacity: 1.0
  right: 7px
td.notes
  [data-tooltip]:not(.is-loading)::before,
  [data-tooltip]:not(.is-disabled)::before,
  [data-tooltip]:not([disabled])::before
    transform: translate(-90%, -100%)
    min-width: 300px
    max-width: 550px
    white-space: pre-wrap
</style>
