import { defineStore } from "pinia";
import { monthEndDates, monthEndsFrom } from "@/utils/period";

// shared state for single member views / components
// TODO: move member fetching / refresh operations here instead of MemberShow?

export const useMemberStore = defineStore("member", {
  state: () => ({
    member: {},
    refresh: false,
  }),

  getters: {
    memberId: (state) => state.member.id,
    periods: (state) => {
      if (state.member.founded_on) {
        return monthEndsFrom(state.member.founded_on);
      } else {
        return monthEndDates(9);
      }
    },
  },

  actions: {
    updateMember(member) {
      this.member = member;
    },
    triggerRefresh() {
      this.refresh = true;
    },
    completeRefresh() {
      this.refresh = false;
    },
  },
});
