// Map env vars to internal configuration

class ConfigError extends Error {
  constructor(message) {
    super(message);
    this.name = "ConfigError";
  }
}

export const api = {
  // absolute URL
  url: process.env.VUE_APP_HEAVYBIT_API,
  // hasura admin secret (dev only use)
  adminSecret: process.env.VUE_APP_HEAVYBIT_API_SECRET,
};

if (!api.url) throw new ConfigError("VUE_APP_HEAVYBIT_API must be in environment");

export const auth = {
  // auth0 domain to connect to
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  // auth0 client ID
  clientID: process.env.VUE_APP_AUTH0_CLIENTID,
  // disable authentication protection (dev only use)
  disabled: !!process.env.VUE_APP_AUTH0_DISABLED,
};

if (!auth.disabled) {
  if (!auth.domain) throw new ConfigError("VUE_APP_AUTH0_DOMAIN must be in environment");
  if (!auth.clientID) throw new ConfigError("VUE_APP_AUTH0_CLIENTID must be in environment");
}

export const general = {
  // user to connect as instead of using auth0 JWT (dev only use)
  forcedUser: process.env.VUE_APP_FORCE_USER,
};

export const google = {
  // API key for public APIs, private ones use OAuth2 auth
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  // Client ID for google API access
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
};

if (!google.apiKey) throw new ConfigError("VUE_APP_GOOGLE_API_KEY must be in environment");
if (!google.clientId) throw new ConfigError("VUE_APP_GOOGLE_CLIENT_ID must be in environment");

export const sentry = {
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_DEPLOY_ENVIRONMENT,
};

export default {
  api,
  auth,
  general,
  google,
  sentry,
};
