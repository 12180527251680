// font-awesome setup
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// import individual icons as needed
import {
  faAngleDoubleDown,
  faArrowDown,
  faArrowCircleRight,
  faBinoculars,
  faBold,
  faBook,
  faBuilding,
  faCaretUp,
  faCaretDown,
  faCheck,
  faCog,
  faDollarSign,
  faDownload,
  faEnvelope,
  faFilter,
  faItalic,
  faListOl,
  faListUl,
  faPen,
  faSearch,
  faUserPlus,
  faSignOutAlt,
  faStrikethrough,
  faSync,
  faTimes,
  faUnlink,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

// import individual brand icons
import {
  faGithubSquare,
  faTwitterSquare,
  faLinkedin,
  faHackerNewsSquare,
  faStackOverflow,
  faSlack,
} from "@fortawesome/free-brands-svg-icons";

// import { faClipboard } from "@fortawesome/free-regular-svg-icons"; // non-solid
library.add(
  faAngleDoubleDown,
  faArrowDown,
  faArrowCircleRight,
  faBinoculars,
  faBold,
  faBook,
  faBuilding,
  faCaretUp,
  faCaretDown,
  faCheck,
  faCog,
  faDollarSign,
  faEnvelope,
  faItalic,
  faListOl,
  faListUl,
  faPen,
  faSearch,
  faSignOutAlt,
  faStrikethrough,
  faTimes,
  faUserPlus,
  faSync,
  faUnlink,
  faFilter,
  faDownload,
  faUser,
  faGithubSquare,
  faTwitterSquare,
  faLinkedin,
  faHackerNewsSquare,
  faStackOverflow,
  faSlack
);

export default FontAwesomeIcon;
