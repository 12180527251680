import auth0 from "auth0-js";
import { auth as config } from "@/common/config";

// generate a new webauth instance
export const webAuth = () => {
  return new auth0.WebAuth({
    clientID: config.clientID,
    domain: config.domain,
    redirectUri: `${window.location.origin}/callback`,
    responseType: "id_token",
    // only get id information for now from auth0. we could also request 'email'
    // or 'profile', but we are intentionally keeping most of our user data in-db
    // to increase future flexibilty
    scope: "openid",
  });
};

export default {
  webAuth,
};
