<template>
  <div id="member-tags" class="tags are-medium">
    <span v-show="member.member_stage" class="tag">
      {{ memberStage }}
    </span>
    <span v-show="member.company_stage" class="tag">
      {{ companyStage }}
    </span>
    <span v-show="member.fundraising" class="tag is-primary">fundraising</span>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  computed: {
    memberStage() {
      return this.member.member_stage && this.member.member_stage.name;
    },
    companyStage() {
      return this.member.company_stage && this.member.company_stage.name;
    },
  },
};
</script>

<style lang="sass" scoped>
span.tag
  height: 2.25rem
  font-variant: small-caps
  padding-bottom: 0.1rem
</style>
