export const capitalize = (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const truncate = (string, count) => {
  return string.length <= count ? string : string.substr(0, count) + "...";
};

export default {
  capitalize,
  truncate,
};
