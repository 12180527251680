// constants

// Users IDs are different from contact IDs and need to be manually resolved as
// there doesn't seem to be any easy other way to get the contact ID from the user ID
export const PARTNERS = {
  // user_id : person.id
  67431: "2281", // Tom
  1760893: "4442", // Joe
  1780175: "390", // Jesse
  74798: "7997", // James
  201702: "10599", // Dana
  15141464: "9500", // Andrea
  811001: "5398", // internal@heavybit.com
};

export default {
  PARTNERS,
};
