<template>
  <div class="event-activity">
    <div v-if="hasActivity" class="tags">
      <span class="tag is-link is-light">
        <span class="icon is-small">
          <font-awesome-icon icon="check" size="xs" />
        </span>
        <a :href="activityUrl" target="_blank">{{ activitySubject }}</a>
        <button class="delete is-small" @click="resetActivity"></button>
      </span>
    </div>
  </div>
</template>

<script>
import pipedriveWeb from "@/common/pipedrive/web";

export default {
  components: {},
  props: {
    event: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
      default: null,
    },
  },
  emits: ["set-activity"],
  data() {
    return {};
  },
  computed: {
    hasActivity() {
      return this.eventActivityId && this.activity?.id;
    },
    eventActivityId() {
      return this.event?.extendedProperties?.private?.activityId;
    },
    activitySubject() {
      return this.activity?.subject;
    },
    activityUrl() {
      return this.hasActivity ? pipedriveWeb.activityUrl(this.activity.id) : "";
    },
  },
  methods: {
    resetActivity() {
      this.$emit("set-activity", { activityId: null });
    },
  },
};
</script>

<style lang="sass" scoped></style>
