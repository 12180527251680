<template>
  <div class="program-activities">
    <h3 class="is-size-5 mb-4">Activities</h3>

    <!-- No activities -->
    <div v-if="activities.length == 0" class="has-text-centered">
      <p class="is-size-6 is-disabled">No program activities found</p>
    </div>

    <!-- Program activities -->
    <div class="activities">
      <div v-for="(day, index) in days" :key="index" class="day media">
        <!-- Date box -->
        <figure class="media-left">
          <EventDate :date="day.day" :show-time="false" />
        </figure>
        <!-- Date events -->
        <div class="media-content">
          <ul>
            <ProgramActivity
              v-for="activity in day.activities"
              :key="activity.id"
              :activity="activity"
              :activity-types="activityTypes"
              :selected-person-id="selectedPersonId"
              @deselect="deselect"
              @select="select"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventDate from "@/components/EventDate";
import ProgramActivity from "@/components/ProgramActivity";
import pipedrive from "@/common/pipedrive";
import { toDate, toDateString } from "@/utils/date";

export default {
  components: {
    EventDate,
    ProgramActivity,
  },
  props: {
    activities: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedPersonId: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },
  emits: ["select", "update"],
  data() {
    return {
      activityTypes: [],
      selectedActivityId: null,
    };
  },
  computed: {
    days() {
      var allDays = this.activities.map((a) => this.dayFor(a));
      var days = [...new Set(allDays)].sort().reverse();
      return days.map((d) => {
        var activities = this.activities.filter((a) => this.dayFor(a) == d);
        return {
          day: d,
          activities: activities,
        };
      });
    },
  },
  mounted() {
    pipedrive.api
      .get("activityTypes")
      .then((response) => {
        var types = response.data.data || [];
        var allowedTypes = [
          "pipeline_meeting",
          "partner_meeting",
          "board_meeting",
          "anti_activity",
          "founder_social",
          "group_session",
          "introduction",
          "meeting",
          "office_hours",
          "staff_office_hours",
          "task",
        ];
        this.activityTypes = types
          .filter((type) => allowedTypes.includes(type.key_string))
          .sort((a, b) => a.name.localeCompare(b.name));
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    dayFor(activity) {
      var date = toDate(activity.start);
      var day = toDateString(date);
      // var day = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      return day;
    },
    deselect(activity) {
      if (this.selectedActivityId != activity.id) return;
      this.selectedActivityId = null;
      this.$emit("select", null);
    },
    select(activity) {
      this.selectedActivityId = activity?.id;
      this.$emit("select", activity);
    },
    update(activity) {
      this.$emit("update", activity);
    },
    fetchActivityTypes() {
      pipedrive.activities.types().then((types) => (this.activityTypes = types));
    },
  },
};
</script>

<style lang="sass" scoped>
.program-activities
  font-size: 0.8rem
.media.day
  > .media-left
    margin-right: 0.5rem
  .media-content ul
    margin-top: -0.5rem
.hidden
  visibility: hidden
</style>
