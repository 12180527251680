// constants

export const FUNDS = [
  { ref: "all", name: "All Heavybit Vehicles", pipedrive_id: 0 },
  {
    ref: "i",
    name: "Heavybit Holdings I, LLC",
    pipedrive_id: 3100,
    segment: "Investor - Accelerator",
  },
  {
    ref: "ii",
    name: "Heavybit Holdings II, LLC",
    pipedrive_id: 3101,
    segment: "Investor - Accelerator",
  },
  {
    ref: "iii",
    name: "Heavybit Holdings III, LP",
    pipedrive_id: 3102,
    segment: "Investor - VC Fund",
  },
  {
    ref: "iv",
    name: "Heavybit Holdings IV, LP",
    pipedrive_id: 3507,
    segment: "Investor - VC Fund",
  },
  {
    ref: "iv-opp",
    name: "Heavybit Holdings IV Opportunity, LP",
    pipedrive_id: 3956,
    segment: "Investor - VC Fund",
  },
  { ref: "v", name: "Heavybit Holdings V, LP", pipedrive_id: 5747, segment: "Investor - VC Fund" },
  {
    ref: "v-opp",
    name: "Heavybit Holdings V Opportunity, LP",
    pipedrive_id: 6054,
    segment: "Investor - VC Fund",
  },
  {
    ref: "v-assoc",
    name: "Heavybit Holdings V Associates, LP",
    pipedrive_id: 6846,
    segment: "Investor - VC Affiliate Fund",
  },
  {
    ref: "spv-i",
    name: "Heavybit Holdings SPV I, LP",
    pipedrive_id: 3104,
    segment: "Investor - SPV",
  },
  {
    ref: "spv-ii",
    name: "Heavybit Holdings SPV II, LP",
    pipedrive_id: 3106,
    segment: "Investor - SPV",
  },
  {
    ref: "spv-iii",
    name: "Heavybit Holdings SPV III, LP",
    pipedrive_id: 5910,
    segment: "Investor - SPV",
  },
  // { ref: "edge", name: "Heavybit Edge, LLC", pipedrive_id: 4112 } // Available for ref, not enabled currently
];

export default {
  FUNDS,
};
