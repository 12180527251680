import { defineStore } from "pinia";

// shared state for google APIs & libraries
export const useGoogleStore = defineStore("google", {
  state: () => ({
    // manual authorization needs to be run for the current user
    authorizationNeeded: false,
    // google libraries (GIS & GAPI) are fully loaded
    librariesLoaded: false,
    // libraries are fully loaded and token check has completed
    ready: false,
    // short lived access token (see README in common/google)
    token: null,
  }),

  getters: {
    // google is fully loaded and we have a live token
    googleAvailable: (state) => state.ready && !!state.token?.token,
  },

  actions: {
    setAuthorizationNeeded(needed) {
      this.authorizationNeeded = needed;
    },
    libraryLoadingComplete() {
      this.librariesLoaded = true;
    },
    resetGoogle() {
      this.authorizationNeeded = true;
      this.token = null;
    },
    setReady(ready) {
      this.ready = ready;
    },
    setToken(token) {
      this.token = token;
    },
  },
});
