<template>
  <div>
    <div id="member-tabs" class="tabs">
      <ul>
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ 'is-active': selected === tab }"
          @click="selectTab(tab)"
        >
          <a>{{ $filters.capitalize(tab) }}</a>
        </li>
      </ul>
    </div>

    <div v-show="selected === 'notes'" id="notes-tab" class="tab">
      <CompanyNotes v-if="pipedriveId" :id="pipedriveId" />
    </div>
    <div v-show="selected === 'team'" id="team-tab" class="tab">
      <TeamMembers v-if="pipedriveId" :id="pipedriveId" />
    </div>
    <div v-show="selected === 'metrics'" id="metrics-tab" class="tab">
      <MetricTable
        ref="metricTable"
        :metrics="metrics"
        :measurements="measurements"
        :visible="selected === 'metrics'"
      />
    </div>
    <div v-show="selected === 'holdings'" id="holdings-tab" class="tab">
      <CompanyHoldings v-if="pipedriveId" :id="pipedriveId" />
    </div>
    <div v-show="selected === 'files'" id="files-tab" class="tab">
      <keep-alive>
        <CompanyFiles v-if="pipedriveId && selected === 'files'" :id="pipedriveId" />
      </keep-alive>
    </div>
    <!-- <div class="tab" id="financing-tab" v-show="selected === 'financing'">
      <section class="section">
        <h2 class="is-size-3">Financing</h2>
        <div id="financing" style="margin-top: 0.5rem">
          Financing events for this company.
        </div>
      </section>
    </div> -->
  </div>
</template>

<script>
import CompanyFiles from "@/components/CompanyFiles.vue";
import CompanyHoldings from "@/components/CompanyHoldings.vue";
import CompanyNotes from "@/components/CompanyNotes.vue";
import MetricTable from "@/components/MetricTable.vue";
import TeamMembers from "@/components/TeamMembers.vue";

export default {
  components: {
    CompanyFiles,
    CompanyHoldings,
    CompanyNotes,
    MetricTable,
    TeamMembers,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    metrics: {
      type: Array,
      required: true,
    },
    measurements: {
      type: Array,
      required: true,
    },
    pipedriveId: {
      type: Number,
      default: null,
    },
    initialTab: {
      type: String,
      default: "notes",
    },
  },
  emits: ["select-tab"],
  data() {
    return {
      tabs: ["notes", "metrics", "team", "holdings", "files"],
      selected: "",
    };
  },
  mounted() {
    this.selected = this.initialTab;
    if (!this.$mousetrap) return;
    this.tabs.forEach((tab) =>
      this.$mousetrap.bind(tab.substring(0, 1).toUpperCase(), () => this.selectTab(tab))
    );
  },
  beforeUnmount() {
    this.tabs.forEach((tab) => this.$mousetrap.unbind(tab.substring(0, 1).toUpperCase()));
  },
  methods: {
    selectTab(tab) {
      this.selected = tab;
      this.$emit("select-tab", tab);
    },
  },
};
</script>

<style lang="sass" scoped>
#member-tabs
  margin-bottom: 0
.tab section
    padding: 1em 0em
</style>
