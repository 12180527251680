// helper methods for managing chart & metric table periods
import { isLastDayOfMonth } from "date-fns";
import { dateStringInChunks } from "@/utils/date";

// an array of <count> dates, each the last day of the month, counting back
// from now. if today is the last day of the month this month will be included,
// otherwise the first date will be the end of last month.
export const monthEndDates = (count) => {
  let now = new Date(Date.now());
  let year = now.getFullYear();
  let month = now.getMonth();
  let ends = [];

  if (isLastDayOfMonth(now)) {
    month += 1;
  }

  [...Array(count)].forEach(() => {
    ends.push(new Date(year, month, 0, 0, 0, 0, 0));
    if (month === 0) {
      month = 11;
      year = year - 1;
    } else {
      month = month - 1;
    }
  });
  return ends.reverse();
};

// returns an array of dates, each at the end of its respective month,
// starts with the last day of the month of startDate and ends with end
// of the most recent full month
export const monthEndsFrom = (startDateStr) => {
  const now = new Date();
  const start = dateStringInChunks(startDateStr);

  let months = now.getMonth() - (start.month - 1);
  if (isLastDayOfMonth(now)) {
    months += 1;
  }
  months += (now.getFullYear() - start.year) * 12;
  return monthEndDates(months);
};

// generate an array of dates for the given year, each is the last
// day of its respective month.
export const yearlyRange = (year) => {
  let range = [];

  [...Array(12).keys()].forEach((month) => {
    range.push(new Date(year, month + 1, 0, 0, 0, 0, 0));
  });

  return range;
};

export default {
  monthEndDates,
  monthEndsFrom,
  yearlyRange,
};
