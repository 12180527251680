// common logic related to authorization

const getIdToken = function () {
  // stored by AuthService
  return localStorage.getItem("id_token");
};

// return bearer authorization header if available, otherwise empty string
export const bearerHeader = function () {
  const token = getIdToken();
  if (!token) return "";
  return `Bearer ${token}`;
};
