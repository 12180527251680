<template>
  <section class="fund-portfolio section">
    <div class="toolbar">
      <h2 class="title is-size-3">Portfolio</h2>

      <div class="tools">
        <div class="field">
          <label class="field-label is-small has-text-weight-semibold" for="showExited">
            Show Exited Companies:
          </label>
          <input id="showExited" v-model="showExited" type="checkbox" />
        </div>

        <div class="field">
          <label class="field-label is-small has-text-weight-semibold">Group By:</label>
          <div class="select is-small">
            <select v-model="selectedGroup" selected="none">
              <option v-for="option in groupOptions" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>

        <button class="button is-small" @click="download">Download</button>
      </div>
    </div>

    <table id="portfolio" class="table is-fullwidth" :class="{ grouped: isGrouped }">
      <thead>
        <tr>
          <th v-if="isGrouped" class="group">
            {{ $filters.capitalize(selectedGroup) }}
          </th>
          <th class="fixed-width" :class="sortClass('name')" @click="sortBy('name')">
            <span>Company</span>
          </th>
          <th class="fixed-width-small" :class="sortClass('stage')" @click="sortBy('stage')">
            <span>Stage</span>
          </th>
          <th class="fixed-width" :class="sortClass('loc')" @click="sortBy('loc')">
            <span>Location</span>
          </th>
          <th>Description</th>
          <!-- <th>Links</th> -->
          <th :class="sortClass('number')" @click="sortBy('number')">
            <span>No.</span>
          </th>
        </tr>
      </thead>

      <!-- No results -->
      <tbody v-if="!companies || companies.length == 0">
        <tr>
          <td colspan="6" class="no-companies">No companies found</td>
        </tr>
      </tbody>

      <!-- Grouped results -->
      <tbody v-for="group in groups" v-else-if="isGrouped" :key="group.id">
        <tr v-for="(company, index) in group.companies" :key="company.id">
          <td v-if="index == 0" :rowspan="group.companies.length" class="group">
            {{ group.name }}
            <p
              v-if="group.companies.length > 3"
              class="is-size-7 has-text-grey-light has-text-weight-normal"
            >
              {{ group.companies.length }} companies
            </p>
          </td>
          <td class="fixed-width">
            <a target="_blank" class="has-text-dark" :href="pipedriveUrl(company.id)">
              {{ company.name }}
            </a>

            <p class="is-size-7 has-text-grey" :class="{ 'is-italic': !segment(company) }">
              {{ segment(company) || "No description" }}
            </p>
          </td>
          <td>{{ stage(company) }}</td>
          <td>
            <span :title="company.address">
              {{ location(company) }}
            </span>
          </td>
          <td>
            <p class="is-size-7 has-text-grey description">{{ description(company) }}</p>
          </td>
          <td class="has-text-right">
            <span class="is-size-4 has-text-grey-light">{{ memberNumber(company) }}</span>
          </td>
        </tr>
        <tr></tr>
      </tbody>

      <!-- Ungrouped results -->
      <tbody v-else>
        <tr v-for="company in sortedCompanies" :key="company.id">
          <td class="fixed-width">
            <a
              target="_blank"
              class="has-text-dark has-text-weight-semibold"
              :href="pipedriveUrl(company.id)"
            >
              {{ company.name }}
            </a>
            <p class="is-size-7 has-text-grey" :class="{ 'is-italic': !segment(company) }">
              {{ segment(company) || "No segment" }}
            </p>
          </td>
          <td>{{ stage(company) }}</td>
          <td>
            <span :title="company.address">
              {{ location(company) }}
            </span>
          </td>
          <td>
            <p class="is-size-7 has-text-grey description">{{ description(company) }}</p>
          </td>
          <td class="has-text-right">
            <span class="is-size-4 has-text-grey-light">{{ memberNumber(company) }}</span>
          </td>
        </tr>
      </tbody>

      <!-- Empty TFOOT with same cell count is hack to get correct styling, should fix CSS and remove -->
      <tfoot>
        <tr>
          <th v-if="isGrouped" class="group"></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<script>
import pipedrive from "@/common/pipedrive";
import { organizationFields } from "@/common/pipedrive/resources";
import { downloadCSVFromTable } from "@/utils/csv";

export default {
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stages: {},
      showExited: true,
      order: "name",
      ascending: false,
      orderings: {
        name: "name",
        stage: "42a854e75204a315e8567793ae2be785941f4256",
        loc: "address_locality",
        number: "a594052a9a5718c812c61022ab2634b8b92b5be0",
      },
      groupOptions: [
        { value: "", text: "None" },
        { value: "stage", text: "Stage" },
        { value: "segment", text: "Segment" },
        { value: "location", text: "Location" },
      ],
      selectedGroup: "stage",
    };
  },
  computed: {
    filteredCompanies() {
      if (this.showExited) return this.companies;
      return this.companies.filter((c) => this.isActive(c));
    },
    sortedCompanies() {
      const key = this.orderings[this.order];
      const dir = this.ascending ? -1 : 1;
      const companies = this.showExited ? [...this.companies] : this.filteredCompanies;
      companies.sort((a, b) => {
        if (a[key] == b[key]) return 0;
        return a[key] < b[key] ? -dir : dir;
      });
      return companies;
    },
    isGrouped() {
      return this.selectedGroup && this.groups.length > 0;
    },
    groups() {
      if (!this.companies || this.companies.length == 0) return [];
      if (this.selectedGroup === "stage") return this.groupsByStage();
      if (this.selectedGroup === "segment") return this.groupsBySegment();
      if (this.selectedGroup === "location") return this.groupsByLocation();
      return [];
    },
  },
  created() {
    this.fetchStages();
  },
  methods: {
    sortBy(key) {
      this.ascending = key == this.order ? !this.ascending : true;
      this.order = key;
    },
    sortClass(key) {
      return {
        sortable: true,
        asc: this.order == key && this.ascending,
        desc: this.order == key && !this.ascending,
      };
    },
    fetchStages() {
      organizationFields
        .details(3996)
        .then((response) => {
          this.stages = response.options.reduce((hash, value) => {
            hash[value.id] = value.label;
            return hash;
          }, {});
        })
        .catch(() => this.$toasted.error("Unable to fetch stages from Pipedrive"));
    },
    groupsByStage() {
      const stageKey = "42a854e75204a315e8567793ae2be785941f4256";
      const groups = [
        {
          id: -1,
          name: "Stage not found",
          companies: this.filteredCompanies.filter((c) => c[stageKey] == null),
        },
        ...Object.keys(this.stages).map((stageId) => {
          return {
            id: stageId,
            name: this.stages[stageId],
            companies: this.filteredCompanies.filter((c) => c[stageKey] == stageId),
          };
        }),
      ];
      return groups.sort(this._sortByStage);
    },
    groupsBySegment() {
      const segmentKey = "528d1845ce1cadb0a6d25069b65836d3986d00c8";
      const segments = this.companies.map((c) => c[segmentKey]).sort();
      const groups = [...new Set(segments)];
      return groups.map((segment) => {
        return {
          id: segment,
          name: segment,
          companies: this.filteredCompanies.filter((c) => c[segmentKey] == segment),
        };
      });
    },
    groupsByLocation() {
      const locations = this.companies.map((c) => this.location(c)).sort();
      const groups = [...new Set(locations)];
      return groups.map((location) => {
        return {
          id: location,
          name: location,
          companies: this.filteredCompanies.filter((c) => this.location(c) == location),
        };
      });
    },
    pipedriveUrl(id) {
      return pipedrive.web.organizationUrl(id);
    },
    websiteUrl(company) {
      const url = company["b25fb441467be944f6983024dd7673be1350aa2b"];
      return url.startsWith("http") ? url : `https://${url}`;
    },
    googleDriveUrl(company) {
      const key = "fe4bc918a3bc8bedd077f9896ffd23781317d936";
      const folder = company[key];
      return `https://drive.google.com/drive/folders/${folder}`;
    },
    isActive(company) {
      const key = "42a854e75204a315e8567793ae2be785941f4256";
      return !["4", "5", "40"].includes(company[key]); // Acquired, Closed, Public Stage IDs, ahhh json strings & numbers
    },
    memberNumber(company) {
      return company["a594052a9a5718c812c61022ab2634b8b92b5be0"];
    },
    location(company) {
      const city = company.address_locality;
      const area = company.address_admin_area_level_1;
      const country = company.address_country;
      const region = area && area != city ? area : country;
      return [city, region].filter(Boolean).join(", ");
    },
    stage(company) {
      const key = "42a854e75204a315e8567793ae2be785941f4256";
      const stageID = company[key];
      if (stageID == null) this.$toasted.error(`Unable to find stage for ${company.name}`);
      return this.stages[stageID || 3]; // If stage is nil default to 3 - Unknown
    },
    description(company) {
      const key = "426a3fe18ecb9344e548ee31614be1dd905d1920";
      const description = company[key] || "";
      return description.length > 140 ? description.substring(0, 140) + "..." : description;
    },
    segment(company) {
      return company["528d1845ce1cadb0a6d25069b65836d3986d00c8"];
    },
    download() {
      downloadCSVFromTable("portfolio");
    },
    _sortByStage(lhs, rhs) {
      const groupOrder = [
        "pre-seed",
        "seed",
        "series a",
        "series b",
        "series c & growth",
        "acquired",
        "public",
        "closed",
      ];
      // const groupNames = groups.map(g => g.name)
      const lIndex = groupOrder.findIndex((g) => g == lhs.name.toLowerCase());
      const rIndex = groupOrder.findIndex((g) => g == rhs.name.toLowerCase());
      return lIndex - rIndex;
    },
  },
};
</script>

<style lang="sass" scoped>
table#portfolio
  margin-top: 1rem
  font-size: 0.9rem
  .fixed-width
    width: 12rem
  .fixed-width-small
    width: 6rem
  td, th
    padding-top: 0.3rem
    padding-bottom: 0.3rem
    padding-left:0
    vertical-align: top
    p.description
      max-width: 25rem
      max-height: 2.5rem
      text-overflow: ellipsis
      overflow: hidden
  thead th
    vertical-align: bottom
    height: unset
  thead th:last-child
    padding-right:0
  thead th.sortable
    cursor: pointer
  thead th span:after,
  thead th span:before
    font-size: 0.8rem
    color: gray
    display: inline
    padding-left: 0.3rem
    position: relative
    top: -0.1rem
  thead th.asc span:after
    content: "\2193"
  thead th.desc span:after
    content: "\2191"
  td.group,
  th.group
    width: 10rem
  td.group
    font-weight: 600
    vertical-align: top
  td.no-companies
    font-weight: 600
    padding-top: 0.9rem
    padding-bottom: 0.9rem
    text-align: center
    color: lightgray
table.company-table.grouped thead th span:after
  content:'' !important
</style>
