import api from "./api";
import resources from "./resources";
import util from "./util";
import web from "./web";

export { api, resources, util, web };

export default {
  api,
  resources,
  util,
  web,
};
